import React, { Component, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AppContext from '../../AppContext';
import AdminNavbar from './navbar/AdminNavbar'
import { useNavigate, Link } from 'react-router-dom';
import styles from '../../assets/scss/modules/adminpage.module.scss';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';
//import logo from "../../assets/media/logo/Logo_Bergfrei_250px-180w.png";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AdminLoginPage extends Component {
    state = {
  		password: '',
      username: '',
    };

    constructor(props) {
    	super(props);
  	}

    onUsernameChange  = (event) => {
      this.setState({username: event.target.value});
    };

    onPasswordChange  = (event) => {
      this.setState({password: event.target.value});
    };

    onLoginFormSubmit  = (event) => {
      event.preventDefault();
      let self = this;
      this.props.context.interAxios.post('/auth/login', {password: this.state.password, email: this.state.username}, function(response) {
        //self.props.onSignIn(response.data);
        //cookies.set('authtoken', {token: response.data.authToken}, { path: '/'});
        self.props.context.onSignIn(response.data);
        self.props.navigate('/admin');
      });
      //this.props.context.onSignIn("Boda71", "12345678");
    }

    render() {
      return(
        <div className={styles.bodywrapper}>
        <MDBContainer className="adminLanding-container">
        <MDBRow tag="form" className='' onSubmit={this.onLoginFormSubmit}  style={{width: "100%"}}>
          <MDBCol lg="auto" sm="3" md="3" className="p-0">
          <AdminNavbar isLoginPage={true}/>
          </MDBCol>
          <MDBCol  sm="9" md="9" center className="text-center p-0">
            <MDBRow className='d-flex align-items-center justify-content-center py-5 m-0'>
            <div  style={{maxWidth: "300px"}}>

              <p>Please login to your admin account</p>


              <MDBInput
                  wrapperClass='mb-4'
                  label='Email address'
                  type='text'
                  id='validationCustom01'
                  required
                  value={this.state.username}
                  onChange={this.onUsernameChange}/>
              <MDBInput
                  wrapperClass='mb-4'
                  label='Password'
                  id='validationCustom02'
                  required
                  type='password'
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  />
              <div className="text-center pt-1 mb-5 pb-1">
                <MDBBtn type='submit' className="danfoss-btn">Sign in</MDBBtn>
              </div>
            </div>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        </MDBContainer>
        </div>
      )

    }
}

export default function AdminLoginPageFunc(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
      if (context.isLoggedIn()) {
        navigate('/admin');
      }
    });
    return <AdminLoginPage context={context} navigate={navigate}/>;
}

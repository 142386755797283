import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import DocumentSectionView from '../components/SectionView/DocumentSectionView.js'
import LinkSectionView from '../components/SectionView/LinkSectionView.js'
import SoftwareSectionView from '../components/SectionView/SoftwareSectionView.js'
import TextSectionView from '../components/SectionView/TextSectionView.js'
import VideoSectionView from '../components/SectionView/VideoSectionView.js'

import ImagesComponent from '../components/ImagesComponent/ImagesComponent.js'
import ViewCard from '../components/Cards/ViewCard.js'


import addSmall from "../assets/media/icons/Add Small.svg";
import arrowLeft from "../assets/media/icons/arrow-circle-left.png";
import edit from "../assets/media/icons/Edit.svg";
import trash from "../assets/media/icons/Trash-red.svg";
import addNew from "../assets/media/icons/Add New.svg";
import question from "../assets/media/logo/Danfoss-logo.png";

import { MDBContainer,
         MDBNavbar,
         MDBNavbarNav,
         MDBNavbarItem,
         MDBNavbarLink,
         MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
         MDBFile,
       } from 'mdb-react-ui-kit';



class ProductPage extends Component {

  constructor(props) {
    super(props);
    this.listInnerRefOverview = React.createRef();
		this.listInnerRefTextsection = React.createRef();
		this.listInnerRefLinksection = React.createRef();
    this.listInnerRefDocumentsection = React.createRef();
    this.listInnerRefVideosection = React.createRef();
    this.listInnerRefRelatedProducts = React.createRef();
    this.state = {product: {},
                  selectedFiles:[],
                  navbarPaddingTop: true,
                  activeItem: 0,
                  navbaritems: [],
                  productOffsetHeight: document.getElementById('productpageNavbar-id')?.firstChild.offsetHeight};

  }


  componentDidMount() {
    document.addEventListener("scroll", this.scrollEventListener);
    this.onGetProduct(this.props.searchParams.get("productId"))
  }

  componentDidUpdate(prewProps, prewState) {
    if(this.state.productOffsetHeight !== document.getElementById('productpageNavbar-id')?.firstChild.offsetHeight){
      this.setState({productOffsetHeight: document.getElementById('productpageNavbar-id')?.firstChild.offsetHeight})
      this.props.context.onProductOffsetHeight(document.getElementById('productpageNavbar-id').firstChild.offsetHeight);
    }
    if(this.state.product.id !== parseInt(this.props.searchParams.get("productId"))){
      document.addEventListener("scroll", this.scrollEventListener);
      this.listInnerRefOverview.current.scrollIntoView({
     });

      this.onGetProduct(this.props.searchParams.get("productId"))
    }
  }

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
	}


  onRefOverview = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefOverview.current.scrollIntoView({
 	 });
	}

	onRefTextsection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefTextsection.current.scrollIntoView({
	 });
	}

	onRefLinksection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefLinksection.current.scrollIntoView({
	 });
	}

  onRefDocumentsection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefDocumentsection.current.scrollIntoView({
	 });
	}

  onRefVideosection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefVideosection.current.scrollIntoView({
	 });
	}

  onRefRelatedProducts = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefRelatedProducts.current.scrollIntoView({
	 });
	}


  scrollEventListener = (event) => {
		const navPadding = window.scrollY < 80 ? true : false;
    const paddingHeight = this.props.context.mainOffsetHeight +
                          this.props.context.submenuOffsetHeight +
                          this.props.context.linkOffsetHeight +
                          this.props.context.productOffsetHeight + 20;
    let tabscroll = 0
    let text_section = document.getElementById('text-section-id');
    let link_section = document.getElementById('link-section-id');
    let document_section = document.getElementById('document-section-id');
    let video_section = document.getElementById('video-section-id');
    let related_products_section = document.getElementById('related-products-section-id');

    if(text_section!== null && window.scrollY > text_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Related text");
    }
    if(document_section!== null && window.scrollY > document_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Documents");
    }
    if(video_section!== null && window.scrollY > video_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Software");
    }
    if(related_products_section!== null && window.scrollY > related_products_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Related products");
    }
		this.setState({ navbarPaddingTop: navPadding,  activeItem: tabscroll });
	}

  onGetProduct = (id) => {
    //this.setState({product: {}});
    let self = this;
    this.props.context.interAxios.get('/product/' + id , function(response) {
      let newNavbaritems = [{value: "Overview", funktion: self.onRefOverview}];
      if(response.data.sections!==undefined){
        if(response.data.sections.filter((k) => (k.sectionType === "LINK")).length > 0){
          newNavbaritems.push({value: "Product range", funktion: self.onRefLinksection});
        }
        if(response.data.sections.filter((k) => (k.sectionType === "DOCUMENT")).length > 0){
          newNavbaritems.push({value: "Documents", funktion: self.onRefDocumentsection});
        }
        if(response.data.software.length > 0){
          newNavbaritems.push({value: "Software", funktion: self.onRefVideosection});
        }
        if(response.data.relatedProducts.length > 0){
          newNavbaritems.push({value: "Related products", funktion: self.onRefRelatedProducts});
        }
      }

      self.setState({product: response.data,
                     relatedProductslist: response.data.relatedProducts,
                     selectedFiles: response.data.images !== undefined ?  response.data.images : [],
                     navbaritems: newNavbaritems});

    })
  }

  truncateString = (str, num) => {
    if(str !== null){
      if (str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
    }
  }

  render(){
    let isLoading = false;
    let paddingHeight = 52;
    paddingHeight = this.props.context.submenuOffsetHeight !== undefined && this.props.context.submenuOffsetHeight !== 0 ? this.props.context.mainOffsetHeight +
                          this.props.context.submenuOffsetHeight +
                          this.props.context.linkOffsetHeight +
                          this.props.context.productOffsetHeight : 52;
    let refMenu = this.state.navbaritems.map((k, n) => {
      return( <MDBNavbarItem key={n} active={this.state.activeItem === n}>
                <MDBNavbarLink to="#" onClick={() => k.funktion(n)} active={this.state.activeItem === n} className="d-flex align-items-center justify-content-center">
                  {k.value}
                </MDBNavbarLink>
              </MDBNavbarItem>
      )
    })

    let urlText= "/productpage?categoryId=" + this.props.searchParams.get("categoryId") + "&productId=";


    return (
      <>
        {this.state.product !== {} ?
        <div className="productpageNavbar d-none d-lg-block" id="productpageNavbar-id">
        <MDBNavbar light bgColor='light'
                   fixed='top'
                   expand='lg'
                   id="withoutScroll"
                   style={{top: this.props.context.mainOffsetHeight !== undefined ? this.props.context.mainOffsetHeight + this.props.context.submenuOffsetHeight + this.props.context.linkOffsetHeight : 0}}>
          <MDBContainer >
            <MDBNavbarNav id="navbarrow" className='mr-auto mb-2 mb-lg-0'>
              {refMenu}
            </MDBNavbarNav>
          </MDBContainer>
        </MDBNavbar>
        </div>:false}

        <MDBContainer>
          <div ref={this.listInnerRefOverview} style={{scrollMarginTop: paddingHeight}}/>
          <section className="overview">
            <div className="wrapper d-none d-lg-block">
              <MDBRow>
                {this.state.product.images !== undefined && this.state.product.images.length > 0 ?
                <MDBCol md="auto">
                  <ImagesComponent array={this.state.product.images}/>
                </MDBCol> :false}
                <MDBCol>
                  <MDBRow className="m-0 pb-2">
                    <span className="title-text p-0">{this.state.product.descriptionTitle}</span>
                  </MDBRow>
                  <div  id="bullet-point">
                    <p className="detail-text" dangerouslySetInnerHTML={{__html: this.state.product.description}}/>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
            <div className="wrapper d-block d-lg-none">
            {this.state.product.images !== undefined && this.state.product.images.length > 0 ?
              <MDBRow className="m-0 pb-2">
              <ImagesComponent array={this.state.product.images}/>
              </MDBRow>:false}
              <MDBRow className="m-0 pb-2">
              <span className="title-text p-0">{this.state.product.descriptionTitle}</span>
              </MDBRow>
              <div  id="bullet-point">
              <p className="detail-text" dangerouslySetInnerHTML={{__html: this.state.product.description}}/>
              </div>
            </div>
          </section>
        </MDBContainer>

        {this.state.product.sections !== undefined ? <>
        <MDBContainer>
          {this.state.product.sections.filter((k) => (k.sectionType === "TEXT")).length > 0 ? <>
          <div ref={this.listInnerRefTextsection} style={{scrollMarginTop: paddingHeight}} id="text-section-id"/>

          <section className="text-section">
            <TextSectionView array={this.state.product.sections.filter((k) => (k.sectionType === "TEXT"))}/>
          </section>
          </>:false}
        </MDBContainer>


        {this.state.product.sections.filter((k) => (k.sectionType === "LINK")).length > 0 ? <>
        <div ref={this.listInnerRefLinksection} style={{scrollMarginTop: paddingHeight}} id="link-section-id"/>
        <section className="link-section">
          <LinkSectionView array={this.state.product.sections.filter((k) => (k.sectionType === "LINK"))}/>
        </section></>:false}

        <MDBContainer>
          {this.state.product.sections.filter((k) => (k.sectionType === "DOCUMENT")).length > 0 ? <>
          <div ref={this.listInnerRefDocumentsection} style={{scrollMarginTop: paddingHeight}} id="document-section-id"/>
          <section className="document-section">
            <DocumentSectionView arrayDocuments={this.state.product.sections.filter((k) => (k.sectionType === "DOCUMENT"))
                  .map((k) => ({id: k.id, name: k.name, sectionType: k.sectionType, type: k.type !== null ? k.type : "Document", url: k.url, validForCountries: k.validForCountries, validForCountriesMultiplicity: k.validForCountriesMultiplicity, language: k.language, validFor: k.validFor}))
                  .concat(this.state.product.sections.filter((k) => (k.sectionType === "VIDEO"))
                  .map((k) => ({id: k.id, name: k.name, sectionType: "DOCUMENT", type: "Video", url: k.url, validForCountries: "All countries", validForCountriesMultiplicity: "Multiple"})))

                  }/>
          </section></>:false}
        </MDBContainer>

        {this.state.product.software.length > 0 ? <>
        <div ref={this.listInnerRefVideosection} style={{scrollMarginTop: paddingHeight}} id="video-section-id"/>
        <section className="video-section">
          <SoftwareSectionView array={this.state.product.software}/>
        </section></>:false}

        {/*<>
        {this.state.product.sections.filter((k) => (k.sectionType === "VIDEO")).length > 0 ? <>
        <div ref={this.listInnerRefVideosection} style={{scrollMarginTop: paddingHeight}} id="video-section-id"/>
        <section className="video-section">
          <VideoSectionView array={this.state.product.sections.filter((k) => (k.sectionType === "VIDEO"))}/>
        </section></>:false}</>*/}

        </>:false}

        {this.state.relatedProductslist !== undefined && this.state.relatedProductslist.length > 0 ?
        <MDBContainer>
        <div ref={this.listInnerRefRelatedProducts} style={{scrollMarginTop: paddingHeight}} id="related-products-section-id"/>
        <section className="related-products-section">
          <MDBRow className="m-0 py-3">
            <span className="title-text p-0">Related products</span>
          </MDBRow>
          <MDBRow className="m-0">
            {this.state.relatedProductslist.map((k, n) => {
              return(
                <MDBCol md="12" key={n} className="p-0 d-flex justify-content-center">
                <Link to={urlText + k.id} style={{width: "100%"}}>
                <div className="pb-3">
                <MDBRow className="m-0 pb-3" style={{borderBottom: "1px solid #D8D8D9"}}>
                  <MDBCol md="2" className="p-0">
                  <div className="photo-area-client">
                    {k.available ?<>
                      {k.firstImage === null ?
                        <div className="quetion-card-products-search d-flex align-items-center justify-content-center">
                          <img src={question} style={{opacity: 1}}/><br/>
                        </div>:
                        <img
                          src={k.firstImage.url}
                          className=' img-thumbnail img-fluid hover-shadow'
                          style={{opacity: 1}}
                          alt=''
                        />
                      }
                    </>:<>
                      {k.firstImage === null ?
                        <div className="quetion-card-products-search d-flex align-items-center justify-content-center" style={{opacity: .2}}>
                          <img src={question} style={{opacity: .3}}/><br/>
                        </div>:
                        <img
                          src={k.firstImage.url}
                          className=' img-thumbnail img-fluid hover-shadow'
                          style={{opacity: .3}}
                          alt=''
                        />
                      }
                    </>}
                  </div>
                  </MDBCol>
                  <MDBCol md="10" className="p-0">
                    <MDBRow className="m-0 pb-2">
                      <span className="title-text">{k.title}</span>
                    </MDBRow>
                    <MDBRow className="m-0">
                      <span className="detail-text-search" dangerouslySetInnerHTML={{__html: this.truncateString(k.description, 200)}} />
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                </div>
                </Link>
                </MDBCol>
              )
            })}
          </MDBRow>
        </section>
        </MDBContainer>:false}
      </>

    );
  }
}
export default function ProductPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <ProductPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} />;
}

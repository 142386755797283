import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';

import Moment from 'moment';
import 'moment/locale/de';


import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBModal,
         MDBModalDialog,
         MDBModalContent,
         MDBModalHeader,
         MDBModalTitle,
         MDBModalBody,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
         MDBFile,
         MDBCarousel,
         MDBCarouselItem,
       } from 'mdb-react-ui-kit';



class ViewHomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {product: {}, selectedFiles:[]}

  }


  componentDidMount() {
    this.onGetHomePage(this.props.homePage);
  }

  onGetHomePage = (homePage) => {
    this.setState({locationTop: homePage.filter(k => k.location === "TOP"),
                     locationMiddleLeft: homePage.filter(k => k.location === "MIDDLE_LEFT"),
                     locationMiddleRight: homePage.filter(k => k.location === "MIDDLE_RIGHT"),
                     locationBottomFirst: homePage.filter(k => k.location === "BOTTOM_FIRST"),
                     locationBottomSecond: homePage.filter(k => k.location === "BOTTOM_SECOND")});
  }


  render(){
    let text = this.state.locationTop !== undefined ? <p dangerouslySetInnerHTML={{__html: this.state.locationTop[0].description}}/> : "";
    return (
      <>
      <MDBModal show={this.props.showModal} tabIndex='-1'>
        <MDBModalDialog size="fullscreen" scrollable  >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Preview</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={this.props.hideModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <MDBContainer>
              {this.state.locationTop !== undefined ?
              <MDBRow className="pb-4">
                <MDBCard className="top-location" style={{backgroundImage: `url(${this.state.locationTop[0].image.url})`}}>
                  <MDBCol md="5" sm="5" size="12" className="ps-4 pt-3">
                    <MDBRow style={{width: "95%"}} className="ps-1 pt-4">
                    <span className="top-title-text">{this.state.locationTop[0].title}</span>
                    </MDBRow>
                    <MDBRow style={{width: "95%"}} className="ps-1 pt-3">
                    <span className="top-description-text"><p dangerouslySetInnerHTML={{__html: this.state.locationTop[0].description}}/></span>
                    </MDBRow>
                    <MDBRow center style={{width: "95%"}} className="ps-1 pt-3">
                      <a href={this.state.locationTop[0].url} target="_blank" className="p-2">
                        <div className="top-link-btn d-flex align-items-center justify-content-center">
                          {this.state.locationTop[0].linkText}
                        </div>
                      </a>
                    </MDBRow>
                  </MDBCol>
                </MDBCard>
              </MDBRow>:false}
              {this.state.locationMiddleLeft !== undefined && this.state.locationMiddleRight !== undefined ?
              <MDBRow className="py-4">
                <MDBCol md="6" sm="6" size="12" className="ps-0 pe-4 pb-3">
                  <a href={this.state.locationMiddleLeft[0].url} target="_blank" className="d-flex align-items-center justify-content-start">
                    <MDBCard className="middle-left-location p-3" style={{backgroundImage: `url(${this.state.locationMiddleLeft[0].image.url})`}}>
                      <div className="top-link-btn d-flex align-items-end justify-content-start pb-2" style={{height: "100%"}}>
                        {this.state.locationMiddleLeft[0].linkText}
                      </div>
                    </MDBCard>
                  </a>
                </MDBCol>
                <MDBCol md="6" sm="6" size="12" className="ps-4 pe-0 pb-3">
                  <a href={this.state.locationMiddleRight[0].url} target="_blank" className="d-flex align-items-center justify-content-start">
                    <MDBCard className="middle-right-location p-3" style={{backgroundImage: `url(${this.state.locationMiddleRight[0].image.url})`}}>
                      <div className="top-link-btn d-flex align-items-end justify-content-start pb-2" style={{height: "100%"}}>
                        {this.state.locationMiddleRight[0].linkText}
                      </div>
                    </MDBCard>
                  </a>
                </MDBCol>
              </MDBRow>:false}
              {this.state.locationBottomFirst !== undefined && this.state.locationBottomSecond !== undefined ?
              <MDBRow className="bottom-carusel pb-5 pt-3">
              <MDBCarousel showControls  showIndicators  interval={80000} className="p-0">
                <MDBCarouselItem
                  className='w-100 d-block'
                  itemId={1}
                  src={this.state.locationBottomFirst[0].image.url}
                  alt='...'
                >
                  <MDBCard className="bottom-first-card">
                    <MDBRow className="pb-4">
                      <span className="bottom-title-text">{this.state.locationBottomFirst[0].title}</span>
                    </MDBRow>
                    <MDBRow>
                      <span className="bottom-description-text"><p dangerouslySetInnerHTML={{__html: this.state.locationBottomFirst[0].description}}/></span>
                    </MDBRow>
                    <MDBRow center className="ps-1 pt-3">
                      <a href={this.state.locationBottomFirst[0].url} target="_blank" >
                        <div className="bottom-link-btn d-flex align-items-center justify-content-center">
                          {this.state.locationBottomFirst[0].linkText}
                        </div>
                      </a>
                    </MDBRow>

                  </MDBCard>

                </MDBCarouselItem>
                <MDBCarouselItem
                  className='w-100 d-block'
                  itemId={2}
                  src={this.state.locationBottomSecond[0].image.url}
                  alt='...'
                >
                  <MDBCard className="bottom-second-card">
                    <MDBRow className="pb-4">
                      <span className="bottom-title-text">{this.state.locationBottomSecond[0].title}</span>
                    </MDBRow>
                    <MDBRow>
                      <span className="bottom-description-text"><p dangerouslySetInnerHTML={{__html: this.state.locationBottomSecond[0].description}}/></span>
                    </MDBRow>
                    <MDBRow center className="ps-1 pt-3">
                      <a href={this.state.locationBottomSecond[0].url} target="_blank">
                        <div className="bottom-link-btn d-flex align-items-center justify-content-center">
                          {this.state.locationBottomSecond[0].linkText}
                        </div>
                      </a>
                    </MDBRow>
                  </MDBCard>
                </MDBCarouselItem>
              </MDBCarousel>
              </MDBRow>:false}
              </MDBContainer>
              </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      </>

    );
  }
}
export default function ViewHomePageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <ViewHomePage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}
                     showModal = {props.showModal} homePage = {props.homePage} hideModal = {props.hideModal}/>;
}

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import linkarrow from '../../assets/media/icons/link arrow.svg'
import link_white from '../../assets/media/icons/link_white.png'


import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBIcon
} from 'mdb-react-ui-kit';

class SoftwareSectionView extends Component {

  constructor(props) {
    super(props);

  }

  downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

	render() {
    let textArray = this.props.array.map((k,n) => {
      console.log(k);
      return(
        <div key={n} className="align-items-center pb-3">
          <MDBRow className="m-0 pb-3">
            <MDBBtn type="btn" className="download-btn" onClick={() => this.downloadURI(k.url, k.name)}>
              <span style={{marginRight: "8px"}}> {k.name}</span><MDBIcon fas icon="download" />
            </MDBBtn>
          </MDBRow>
          <MDBRow className="m-0 p-0">
              <span className="software-detail-text p-0"><p dangerouslySetInnerHTML={{__html: k.description}}/></span>
          </MDBRow>
        </div>
      )
    })
    return  (
        <>
        <MDBContainer>
          <MDBRow className="m-0 py-3">
          <span className="title-text p-0">Software</span>
          </MDBRow>
          <MDBRow className="m-0 ">
          <div className="p-0">
            {textArray}
          </div>
          </MDBRow>
        </MDBContainer>
        </>
  );
  }
}
export default SoftwareSectionView;

import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


class Imagecrop extends PureComponent {
  state = {
    src: null,
    crop: {
      unit: 'px',
      x: 25,
      y: 25,
      width: 357,
      height: 238,
    },
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width; //1080 / 402
    const scaleY = image.naturalHeight / image.height; //396 / 147
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {

      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({url: this.fileUrl, blob: blob});
      }, 'image/jpeg');
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);

    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = event => {
    if(event.target.naturalWidth > 500){
      this.setState({imageRef: event.target});
    }else{
      this.props.hideModal();
      alert("Image size not correct. Please add at least 600px wide image.")
    }

  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.state.imageRef && crop.width && crop.height) {

      const croppedImage = await this.getCroppedImg(
        this.state.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.props.onMakeCroppedImage(croppedImage);
      this.setState({ croppedImageUrl: croppedImage.url});
    }
  }



  render() {

    return (
      <div className="">
      <div className="pb-3">
        <input type="file" accept="image/*" onChange={this.onSelectFile} />
      </div>
      {this.state.src && (
        <ReactCrop
          ruleOfThirds
          locked
          onChange={this.onCropChange}
          crop={this.state.crop}
          onComplete={this.onCropComplete}
        >
          <img alt="Crop" style={{ maxWidth: '100%'}}
                          src={this.state.src}
                          onLoad={this.onImageLoaded}
                          />
        </ReactCrop>
      )}
      {this.state.croppedImageUrl && (
        <div className="d-flex align-items-center justify-content-center">
        <img alt="Crop" style={{ maxWidth: '60%' }} src={this.state.croppedImageUrl} />
        </div>
      )}
    </div>
    );
  }
}
export default Imagecrop;
//ReactDOM.render(<App />, document.getElementById('root'));

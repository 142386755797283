import React, { Component } from 'react';
import AppContext from './AppContext';
import DanfossAxios from './Service/DanfossAxios';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getToken = () => {
    return cookies.get('authtoken')?.token;
}

class AppContextProvider extends Component {

    state = {
        interAxios: new DanfossAxios('App', getToken), linkOffsetHeight : 0
    };

    onSignIn = (responseData) => {
        cookies.set('authtoken', {
            token: responseData.authToken,
            user: responseData.user,
            role: responseData.role
          }, { path: '/'}
        );
        //this.state.interAxios.setToken(responseData.authToken);
    }

    onLogout = () => {
        cookies.remove('authtoken', { path: '/'});
    }

    isLoggedIn = () => {
        let cookieToken = getToken();
        //console.log(this.parseJwt(cookieToken));
        return cookieToken !== undefined && this.parseJwt(cookieToken)?.exp > (Date.now() / 1000);
    }

    isSuperAdmin = () => {
      return this.isLoggedIn() && cookies.get('authtoken')?.role === 'ROLE_SUPERADMIN';
    }

    parseJwt = (token) => {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch (e) {
            console.log(e);
          return null;
        }
    };

    onLinkOffsetHeight = (linkOffsetHeight) => {
      this.setState({ linkOffsetHeight: window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "home" ? linkOffsetHeight : 0 });
    }
    onMainOffsetHeight = (mainOffsetHeight) => {
      this.setState({ mainOffsetHeight: mainOffsetHeight });
    }
    onSubmenuOffsetHeight = (submenuOffsetHeight) => {
      this.setState({ submenuOffsetHeight: submenuOffsetHeight });
    }
    onProductOffsetHeight = (productOffsetHeight) => {
      this.setState({ productOffsetHeight: productOffsetHeight });
    }
    onOtherOffsetHeight = (otherOffsetHeight) => {
      this.setState({ otherOffsetHeight: otherOffsetHeight });
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    interAxios: this.state.interAxios,
                    isLoggedIn: this.isLoggedIn,
                    onSignIn: this.onSignIn,
                    onLogout: this.onLogout,
                    isSuperAdmin: this.isSuperAdmin,
                    onLinkOffsetHeight: this.onLinkOffsetHeight,
                    linkOffsetHeight: this.state.linkOffsetHeight,
                    onMainOffsetHeight: this.onMainOffsetHeight,
                    mainOffsetHeight: this.state.mainOffsetHeight,
                    onSubmenuOffsetHeight: this.onSubmenuOffsetHeight,
                    submenuOffsetHeight: this.state.submenuOffsetHeight,
                    onProductOffsetHeight: this.onProductOffsetHeight,
                    productOffsetHeight: this.state.productOffsetHeight,
                    onOtherOffsetHeight: this.onOtherOffsetHeight,
                    otherOffsetHeight: this.state.otherOffsetHeight
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppContextProvider;

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import arrowUp from "../../assets/media/icons/Arrow_Up_red.svg";
import arrowDown from "../../assets/media/icons/Arrow_Down_red.svg";
import download from "../../assets/media/icons/Download.svg";
import info from "../../assets/media/icons/Info.svg";
import sortby from "../../assets/media/icons/Sort_by.svg";
import videolink from "../../assets/media/icons/videolink.png";


import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBTooltip,
  MDBIcon,
  MDBSelect
} from 'mdb-react-ui-kit';

class DocumentSectionView extends Component {

  constructor(props) {
    super(props);
    this.state = {//isSorted: false,
                  languageFilter: Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))),
                  typeFilter: Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))),
                  documentstArraySorted: this.props.arrayDocuments.filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).includes(k.language)).filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).includes(k.type)).sort((a, b) => a.name.localeCompare(b.name)),
                  documentstArrayReverse: this.props.arrayDocuments.filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).includes(k.language)).filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).includes(k.type)).sort((a, b) => b.name.localeCompare(a.name)),
                  languageDropDownArray: [], typeDropDownArray:[],

                };
  }

  componentDidMount(){
    let languageDropDownArray = [];
    let typeDropDownArray = [];
    for(let i=0; i<Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).length; i++){
      languageDropDownArray.push({text: Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language)))[i]});
    };
    for(let i=0; i<Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).length; i++){
      typeDropDownArray.push({text: Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type)))[i]});
    };
    this.setState({languageDropDownArray: languageDropDownArray, typeDropDownArray: typeDropDownArray})

  }

  onSorted = () => {
    this.setState({isSorted: !this.state.isSorted,
                   })
  }

  onLanguageFilter = (e) => {
    let array = e.map(k => k.text);
    this.setState({languageFilter: array})
  }

  onTypeFilter = (e) => {
    let array = e.map(k => k.text);
    this.setState({typeFilter: array})
  }

	render() {
    return  (
        <>
        <MDBRow className="m-0 py-3">
        <span className="title-text p-0">Documents</span>
        </MDBRow>
        <div className="d-none d-lg-block">
        <MDBTable align="middle">
         <MDBTableHead>
             <tr>
               <th  className="half-a-border-on-right" scope='col' style={{width: "11%"}}>
               <MDBRow className="" style={{height:"38px"}}>
                 <MDBCol md="8" size="8" className="d-flex align-items-center justify-content-start">Type</MDBCol>
                 <MDBCol md="4" size="4" className="d-flex align-items-center justify-content-end">
                 <div className="documentSection-dropdown">
                   <MDBSelect
                    data={this.state.typeDropDownArray}
                    multiple
                    onValueChange={e => this.onTypeFilter(e)}
                  />
                 </div>
                 </MDBCol>
               </MDBRow>
               </th>
               <th  className="half-a-border-on-right" scope='col' style={{width: "22%"}}>
                <MDBRow className="" style={{height:"38px"}}>
                  <MDBCol md="8" size="8" className="d-flex align-items-center justify-content-start">Name</MDBCol>
                  <MDBCol md="4" size="4" className="d-flex align-items-center justify-content-end">
                    <MDBBtn type="btn" className="sorted-button" onClick={()=>this.onSorted()}>
                      <img src={this.state.isSorted ? arrowDown : arrowUp}/>
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
               </th>
               <th  className="half-a-border-on-right text-center " scope='col' style={{width: "15%"}}>
               <MDBRow className="" style={{height:"38px"}}>
                 <MDBCol md="8" size="8" className="d-flex align-items-center justify-content-start">Language</MDBCol>
                 <MDBCol md="4" size="4" className="d-flex align-items-center justify-content-end">
                 <div className="documentSection-dropdown">
                   <MDBSelect
                    data={this.state.languageDropDownArray}
                    multiple
                    onValueChange={e => this.onLanguageFilter(e)}
                  />
                 </div>
                 </MDBCol>
               </MDBRow>
               </th>
               <th  className="half-a-border-on-right" scope='col' style={{width: "13%", height: "38px"}}>
                 <MDBRow className="d-flex align-items-center justify-content-center" style={{height:"38px"}}>
                  Valid for
                 </MDBRow>
               </th>
               <th  className="half-a-border-on-right" scope='col' style={{width: "13%"}}>
                 <MDBRow className="d-flex align-items-center justify-content-center" style={{height:"38px"}}>
                  Update
                 </MDBRow>
               </th>
               <th  className="half-a-border-on-right"scope='col' style={{width: "13%"}}>
                 <MDBRow className="d-flex align-items-center justify-content-center" style={{height:"38px"}}>
                  Download
                 </MDBRow>
               </th>
               <th  className="half-a-border-on-right" scope='col' style={{width: "13%"}}>
                 <MDBRow className="d-flex align-items-center justify-content-center" style={{height:"38px"}}>
                  File type
                 </MDBRow>
               </th>
             </tr>
         </MDBTableHead>
         <MDBTableBody>
           {this.state.isSorted ?
             this.props.arrayDocuments.filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).includes(k.language)).filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).includes(k.type)).sort((a, b) => a.name.localeCompare(b.name)).map((k,n) => {
               if(this.state.typeFilter.map(k => {k.text; return k}).includes(k.type) && this.state.languageFilter.includes(k.language)){
                 let validfor = k.validForCountries !== undefined ? (k.validForCountriesMultiplicity === "Single" ? k.validForCountries : "Multiple") : "N/A";
                 return(
                     <tr key={n} className={n % 2 != 0 ? "table-active" : ""}>
                       <td>{k.type}</td>
                       <td><b>{k.name}</b></td>
                       <td className="text-center">{k.language}</td>
                       <td className="text-center">
                             <MDBRow className="m-0">
                               <MDBCol md="8"  size="6" className=" d-flex align-items-center justify-content-end">
                               {validfor}
                               </MDBCol>
                               {validfor === "Multiple" ?
                               <MDBCol md="4"  size="6" className=" d-flex align-items-center justify-content-end">
                               <div className="info-tooltip d-flex align-items-center justify-content-center">
                                 <MDBTooltip placement="right" title={k.validForCountries}>
                                   <img src={info}/>
                                 </MDBTooltip>
                               </div>
                               </MDBCol>:false}
                             </MDBRow>
                       </td>
                       <td className="text-center">{k.validFor}</td>
                       <td className="text-center">
                         {k.url !== undefined ?
                           <a href={k.url} target="_blank" className="d-flex align-items-center justify-content-center">
                             <img src={k.type === "Video" ? videolink : download}/>
                           </a>:false}
                       </td>
                       <td className="text-center">{k.url !== undefined ?<>{k.type === "Video" ? ".video" : ".pdf"}</>:false}</td>
                     </tr>
                 )
               }

           }):
          this.props.arrayDocuments.filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).includes(k.language)).filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).includes(k.type)).sort((a, b) => b.name.localeCompare(a.name)).map((k,n) => {
             if(this.state.typeFilter.map(k => {k.text; return k}).includes(k.type) && this.state.languageFilter.includes(k.language)){
               let validfor = k.validForCountries !== undefined ? (k.validForCountriesMultiplicity === "Single" ? k.validForCountries : "Multiple") : "N/A";
               return(
                   <tr key={n} className={n % 2 != 0 ? "table-active" : ""}>
                     <td>{k.type}</td>
                     <td><b>{k.name}</b></td>
                     <td className="text-center">{k.language}</td>
                     <td className="text-center">
                           <MDBRow className="m-0">
                             <MDBCol md="8"  size="6" className=" d-flex align-items-center justify-content-end">
                             {validfor}
                             </MDBCol>
                             {validfor === "Multiple" ?
                             <MDBCol md="4"  size="6" className=" d-flex align-items-center justify-content-end">
                             <div className="info-tooltip d-flex align-items-center justify-content-center">
                               <MDBTooltip placement="right" title={k.validForCountries}>
                                 <img src={info}/>
                               </MDBTooltip>
                             </div>
                             </MDBCol>:false}
                           </MDBRow>
                     </td>
                     <td className="text-center">{k.validFor}</td>
                     <td className="text-center">
                       {k.url !== undefined ?
                         <a href={k.url} target="_blank" className="d-flex align-items-center justify-content-center">
                           <img src={k.type === "Video" ? videolink : download}/>
                         </a>:false}
                     </td>
                     <td className="text-center">{k.url !== undefined ?<>{k.type === "Video" ? ".video" : ".pdf"}</>:false}</td>
                   </tr>
               )
             }
           })}
         </MDBTableBody>
       </MDBTable>
        </div>
        <div className="d-block d-lg-none">
          <MDBTable>
           <MDBTableHead>
               <tr>
                 <th  className="half-a-border-on-right" scope='col' style={{width: "22%"}}>
                  <MDBRow className="" style={{height:"38px"}}>
                    <MDBCol md="8" size="8" className="d-flex align-items-center justify-content-start">Name</MDBCol>
                    <MDBCol md="4" size="4" className="d-flex align-items-center justify-content-end">
                      <MDBBtn type="btn" className="sorted-button" onClick={this.onSorted}>
                        <img src={this.state.isSorted ? arrowDown : arrowUp}/>
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                 </th>
                 <th  className="half-a-border-on-right"scope='col' style={{width: "13%"}}>
                   <MDBRow className="d-flex align-items-center justify-content-center" style={{height:"38px"}}>
                    Download
                   </MDBRow>
                 </th>
               </tr>
           </MDBTableHead>
           <MDBTableBody>

           {this.state.isSorted ? this.props.arrayDocuments.filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).includes(k.language)).filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).includes(k.type)).sort((a, b) => a.name.localeCompare(b.name)).map((k,n) => {

             return(
                 <tr key={k.name} className={n % 2 != 0 ? "table-active" : ""}>
                   <td><b>{k.name}</b></td>
                   <td className="text-center">
                       <a href={k.url} target="_blank" className="d-flex align-items-center justify-content-center">
                         <img src={k.type === "Video" ? videolink : download}/>
                       </a>
                   </td>
                 </tr>
             )
           }):
           this.props.arrayDocuments.filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.language))).includes(k.language)).filter(k => Array.from(new Set(this.props.arrayDocuments.map(k =>  k.type))).includes(k.type)).sort((a, b) => b.name.localeCompare(a.name)).map((l,m) => {

             return(
                 <tr key={l.name} className={m % 2 != 0 ? "table-active" : ""}>

                   <td><b>{l.name}</b></td>
                   <td className="text-center">
                       <a href={l.url} target="_blank" className="d-flex align-items-center justify-content-center">
                         <img src={l.type === "Video" ? videolink : download}/>
                       </a>
                   </td>
                 </tr>
             )
           })}
             {/*documentstArrayRespo*/}
           </MDBTableBody>
         </MDBTable>
        </div>
        </>
  );
  }
}
export default DocumentSectionView;

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import linkarrow from '../../assets/media/icons/link arrow.svg'


import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBContainer
} from 'mdb-react-ui-kit';

class LinkSectionView extends Component {

  constructor(props) {
    super(props);

  }



	render() {
    let textArray = this.props.array.map((k,n) => {
      return(
        <div key={n} className="d-flex align-items-center ">
          <MDBCol md="4" center>
            <img src={linkarrow}/>
            <a href={k.url} target="_blank">{k.name}</a>
          </MDBCol>
        </div>
      )
    })
    return  (
        <>
        <MDBContainer>
          <MDBRow className="m-0 py-3">
          <span className="title-text p-0">Product range</span>
          </MDBRow>
        </MDBContainer>
          <div  className="link-frame d-flex align-items-center justify-content-center">
          <MDBContainer>
            <div className="link-inner-frame">
              <MDBRow className="m-0 pb-2">
              {textArray}
              </MDBRow>
            </div>
          </MDBContainer>
          </div>
        </>
  );
  }
}
export default LinkSectionView;

import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'moment';
import 'moment/locale/de';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer } from 'react-sortable-hoc';

import DeleteModal from '../modals/categories/DeleteModal.js';
import DragAndDrop from '../components/DragAndDrop.js';
import VideoComponent from "../section/VideoSection";
import UrlComponent from "../section/UrlSection";
import DocumentComponent from "../section/DocumentSection";
import TextComponent from "../section/TextSection";
import SelectSectionModal from '../modals/products/SelectSectionModal.js';
import CreateImageCategory from "../modals/categories/CreateImageCategory";

import ViewPage from "./ViewPage";

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
       } from 'mdb-react-ui-kit';
import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-and-drop';
class OtherPagesDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {isDeleteFunction: false,
      propba: [],
                  description:"",
                  name: "Add page name",
                  showAddImage: false};

  }

  componentDidMount() {
    if(this.props.searchParams.get("otherPageId") !== ""){
      this.onGetPageById(this.props.searchParams.get("otherPageId"));
    }

  }
  onGetPageById = (id) => {
    let self = this;
    this.props.context.interAxios.get('/otherpage/' + id , function(response) {
      let array = response.data.sections;
      for(let i=0; i< array.length; i++){
        if(array[i].sectionType === "TEXT"){
          array[i].sectionType = TextComponent;
        }
        if(array[i].sectionType === "VIDEO"){
          array[i].sectionType = VideoComponent;
        }
        if(array[i].sectionType === "LINK"){
          array[i].sectionType = UrlComponent;
        }
        if(array[i].sectionType === "DOCUMENT"){
          array[i].sectionType = DocumentComponent;
        }
      }
      self.setState({name: response.data.name,
                     descriptionTitle: response.data.descriptionTitle,
                     description: response.data.description,
                     selectedFiles: response.data.images !== undefined ? response.data.images : [],
                     sectionArray: array,});
    })
  }

  onSavePage = () => {
    if(this.state.name !== "Add page name"){
      let array = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
      for(let i=0; i< array.length; i++){
        if(!array[i].trashBin){
          if(array[i].sectionType === TextComponent){
            array[i].sectionType = "TEXT";
          }
          if(array[i].sectionType === VideoComponent){
            array[i].sectionType = "VIDEO";
          }
          if(array[i].sectionType === UrlComponent){
            array[i].sectionType = "LINK";
          }
          if(array[i].sectionType === DocumentComponent){
            array[i].sectionType = "DOCUMENT";
            //array[i].type = "PDF";
            //array[i].validFor = Moment(Date.now()).format('YYYY-MM-DD');
          }
        }else{
          if(array[i].trashBin){
            array.splice(i, 1)
          }
        }
      }
      let self = this;
      if(this.props.searchParams.get("otherPageId") !== ""){
        this.props.context.interAxios.put('/otherpage/' + this.props.searchParams.get("otherPageId"),
        {name: this.state.name,
         description: this.state.description,
         sections: array
        }
        , function(response) {
          self.handleSaveImage(response.data.id);
        })
      }else{
        this.props.context.interAxios.post('/otherpage',
        {name: this.state.name,
         description: this.state.description,
         sections: array
        }
        , function(response) {
          self.handleSaveImage(response.data.id);
        })
      }
    }
  }

  handleShowAddImage = () => {
    let array = this.state.selectedFiles !== undefined ? this.state.selectedFiles.filter((k)=>(!k.trashBin)) : [];
    if(array.length < 21){
      this.setState({showAddImage: true});
    }else{
      alert("You can upload a maximum of 20 images");
    }

  };

  handleCloseAddImage = (image, id) => {
    this.setState({showAddImage: false});
  };

  onCropComplete = (image, id) => {
    this.setState({url: image.url, blob: image.blob});
    let list = this.state.selectedFiles !== undefined ? this.state.selectedFiles : [];
    list.push({url: image.url, value: image.blob});

    this.setState({selectedFiles: list});
  };

  onFileChange = (e) => {
    let list = this.state.selectedFiles !== undefined ? this.state.selectedFiles : [];
    for (let i = 0; i < e.target.files.length; i++) {
      list.push({url: URL.createObjectURL(e.target.files[i]), value: e.target.files[i],});
    }
    this.setState({selectedFiles: list});
  };

  handleSaveImage = (productId) => {
    let prom=[];
    let array = this.state.selectedFiles
    for(let i=0; i<this.state.selectedFiles.length; i++){
      if(!this.state.selectedFiles[i].trashBin && this.state.selectedFiles[i].value !== undefined){
        let self = this;
        const formData = new FormData
        formData.append("file", this.state.selectedFiles[i].value);
        prom.push(this.props.context.interAxios.post('/image', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
          array[i].id=response.data.id;
          array[i].url=response.data.url
        }));
      }
    }
    Promise.all(prom).then(() => this.handleImageConnect(productId, array));
  }

  handleImageConnect = (pageId, imageIdArray) => {
    let prom=[];
    let imageIdList = imageIdArray.filter((k)=>(!k.trashBin)).map((k)=>(k.id));
    let self = this;
    prom.push(this.props.context.interAxios.put('/otherpage/' + pageId + '/connect-images-ordered', imageIdList, function(response) {}));
    Promise.all(prom).then(() => self.backOtherPages());
    
  }

  addNameToSection = (index, name) => {
    let array = this.state.sectionArray;
    array[index].name=name;
    this.setState({sectionArray: array});
  }
  addTextToSection = (index, text) => {
    let array = this.state.sectionArray;
    array[index].text=text;
    this.setState({sectionArray: array});
  }
  addUrlToSection = (index, url) => {
    let array = this.state.sectionArray;
    array[index].url=url;
    this.setState({sectionArray: array});
  }

  backOtherPages = () => {
    const searchParams = new URLSearchParams();
    this.props.navigate("/admin/otherpages");
  }



  addVideoSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: VideoComponent});
     this.setState({sectionArray: sectionArray});
  }

  addUrlSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: UrlComponent});
     this.setState({sectionArray: sectionArray});
  }

  addDocumentSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: DocumentComponent});
     this.setState({sectionArray: sectionArray});
  }

  addTextSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: TextComponent});
     this.setState({sectionArray: sectionArray});
  }

  onDeleteSection = (index) => {
    let list = this.state.sectionArray;
    list[index].trashBin = true;
    this.setState({sectionArray: list});
  }

  showModal = () => {
    this.setState({showModal: true, status: "Select the section item"})
  };

  hideModal = () => {
    this.setState({showModal: false, status: ""})
  }


  onDeleteFunction = () => {
    this.setState({isDeleteFunction: !this.state.isDeleteFunction})
  }

  onArticleChange = (editorState) => {
    this.setState({description: editorState});
  }
  onTitleChange = (event) => {
    this.setState({name: event.target.value});
	}

  onDeleteChange = (index) => {
    let list = this.state.selectedFiles
    list[index].trashBin = true;
    this.setState({selectedFiles: list});
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(
      {selectedFiles: arrayMoveImmutable(this.state.selectedFiles, oldIndex, newIndex)}
    );
  }

  showPreview = () => {
    let product = {};
      product = {title: this.state.title,
                    descriptionTitle: this.state.descriptionTitle,
                    description: this.state.description,
                    sections: this.state.sectionArray !== undefined ? this.state.sectionArray : [],
                    status: true,
                    software: [],
                    images: this.state.selectedFiles.filter(k => !k.trashBin)
                  };
      this.setState({showPreview: true, previewProduct: product})
  }

  hidePreview = () => {
    this.setState({showPreview: false});
    let array = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
    for(let i=0; i< array.length; i++){
      if(array[i].sectionType === "TEXT"){
        array[i].sectionType = TextComponent;
      }
      if(array[i].sectionType === "VIDEO"){
        array[i].sectionType = VideoComponent;
      }
      if(array[i].sectionType === "LINK"){
        array[i].sectionType = UrlComponent;
      }
      if(array[i].sectionType === "DOCUMENT"){
        array[i].sectionType = DocumentComponent;
      }
    }
  }


  render(){
    let productName = this.props.searchParams.get("otherPageId") !== "" ? "Edit " + this.state.name : "Add new page";
    let sectionList = [];
    if(this.state.sectionArray !== undefined){
      sectionList = this.state.sectionArray.map(
        (k, n) => {
          let Tag = k.sectionType;
          return <Tag key={n}
                      id={k.id}
                      index={n}
                      onDeleteSection={this.onDeleteSection}
                      name={k.name}
                      text={k.text}
                      url={k.url}
                      trashBin={k.trashBin !== undefined ? k.trashBin : false}
                      addNameToSection={this.addNameToSection}
                      addTextToSection={this.addTextToSection}
                      addUrlToSection={this.addUrlToSection}
                      />;
            }
      )
    }

    return (
      <div style={{width: "100%"}} className="px-2">
        <MDBRow className="pt-5">
          <MDBCol center md="2" sm="2" className="padding-col">
            <MDBBtn type="btn" onClick={this.backOtherPages} className="transparent-btn">
              <div className="d-flex align-items-center justify-content-center">
                <img src={arrowLeft}/>
              </div>
            </MDBBtn>
          </MDBCol>
          <MDBCol center className="margin-col">
          <h2 className="title-text">{productName}</h2>
          <span className="url-text">{"Other pages - " + productName}</span>
          </MDBCol>
          <MDBCol center md="auto" className="col-btn" style={{marginLeft: "73px"}}>
              <MDBBtn type="btn" onClick={this.onSavePage} className="newpage-btn">
                <div className="d-flex align-items-center justify-content-center">
                  <span>Save</span>
                </div>
              </MDBBtn>
          </MDBCol>
          <MDBCol center md="auto" className="col-btn">
              <MDBBtn type="btn" onClick={this.showPreview} className="newpage-btn">
                <div className="d-flex align-items-center justify-content-center">
                  <span>Preview</span>
                </div>
              </MDBBtn>
          </MDBCol>
        </MDBRow>
      <div className="section-part">
        <MDBRow tag="form" className='py-3'>
            <span className="add-title-text">Product name</span>
            <div style={{maxWidth: "553px"}}>
            <MDBInput
                          wrapperClass='mb-2'
                          type='text'
                          id='name'
                          value={this.state.name}
                          onChange={this.onTitleChange}/>
            </div>

        </MDBRow>

        <span className="add-title-text">Product image</span>
          <MDBBtn type="btn" className="deletefunction-btn" onClick={this.onDeleteFunction}><img src={edit}/></MDBBtn>
        <span className="add-normal-text">(Delete function)</span>
        <div ref={this.props.nodeRef}>
          <DragAndDrop isDeleteFunction={this.state.isDeleteFunction}
                       onFileChange={this.onFileChange}
                       selectedFiles={this.state.selectedFiles !== undefined ? this.state.selectedFiles : []}
                       onDeleteChange={this.onDeleteChange}
                       handleShowAddImage={this.handleShowAddImage}
                       onSortEnd={this.onSortEnd}/>
        </div>
        <br/><span className="comment-text">Your first picture is the thumbnail! Just simply drag and drop the image you want</span>
        <MDBRow tag="form" className='py-3'>
          <span className="add-title-text">Product description<span className="add-normal-text"> (400 character)</span></span>
          <div style={{maxWidth: "553px"}}>
          <CKEditor
            editor={ ClassicEditor }
            data={this.state.description}
            config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
            onChange={
              ( event, editor ) => {
                const data = editor.getData();
                this.onArticleChange(data);
              }
            }
          />
          </div>
        </MDBRow>
        {sectionList}

      </div>
      <MDBRow className="m-0">
      <div className="addNew-section d-flex align-items-center justify-content-center">
        <MDBBtn type="btn" onClick={this.showModal}>

            <div style={{height: "60%"}} className="d-flex align-items-end justify-content-center">
              <img src={addNew}/><br/>
            </div>
            <div className="d-flex align-items-end justify-content-center pt-1">
              <p>Add new Section</p>
            </div>

        </MDBBtn>
        </div>
      </MDBRow>
      {this.state.showModal ?
      <SelectSectionModal hideModal={this.hideModal}
                          showModal={this.state.showModal}
                          addVideoSection={this.addVideoSection}
                          addUrlSection={this.addUrlSection}
                          addDocumentSection={this.addDocumentSection}
                          addTextSection={this.addTextSection}/> : false}
      {this.state.showAddImage ?
       <CreateImageCategory
                 showModal= {this.state.showAddImage}
                 onCropComplete = {this.onCropComplete}
                 hideModal = {this.handleCloseAddImage}
                 />: false}
      {this.state.showPreview ?
      <ViewPage
                showModal= {this.state.showPreview}
                product = {this.state.previewProduct}
                hideModal = {this.hidePreview}
                />: false}

      </div>


    );
  }
}
export default function OtherPagesDetailFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <OtherPagesDetail context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}/>;
}

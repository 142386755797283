import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import danfossLogo from "../../../assets/media/logo/Danfoss logo.svg";
import Moment from 'moment';
import 'moment/locale/de';


import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBModal,
         MDBModalDialog,
         MDBModalContent,
         MDBModalHeader,
         MDBModalTitle,
         MDBModalBody,
         MDBModalFooter,
         MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBInput,
         MDBIcon
       } from 'mdb-react-ui-kit';



class UserServiceModal extends Component {

  constructor(props) {
    super(props);
    this.state = {userlist: []}

  }


  componentDidMount() {
    this.onGetAllUser();
  }

  componentDidUpdate(prewProps, prewState) {
  }

	componentWillUnmount() {
	}

  onGetAllUser = () => {
    let self = this;
    this.props.context.interAxios.get('/user', function(response) {
      self.setState({userlist: response.data});
    })
  }

  onCreateUser = () => {
    let self = this;
    this.props.context.interAxios.post('/user', {
                      email: this.state.email,
                      role: "ADMIN",
                      password: this.state.password
    }, function(response) {
      self.setState({email: "", password: ""})
      self.onGetAllUser();
      self.onCancelUser();
    });
  }

  onSelectUser = (id, updateFunktion)=> {
    console.log(this.state.userlist);
    let user = this.state.userlist.length>0 && this.state.userlist !== undefined ? this.state.userlist.filter(k => k.id === id)[0] : {email: ""};
    console.log(user);
    this.setState({email: user!== undefined ? user.email : "", originalEmail: user!== undefined ? user.email : "", updateFunktion: updateFunktion, showUserFunction: true})
  }

  onCancelUser = ()=> {
    this.setState({showUserFunction: false, updateFunktion: false})
  }

  onUpdateUser = () => {
    let self = this;
    this.props.context.interAxios.put('/user/' + this.state.originalEmail, {
                      email: this.state.email,
                      role: "ADMIN",
                      password: this.state.password
    }, function(response) {
      self.setState({email: "", password: ""})
      self.onGetAllUser();
      self.onCancelUser();
    });
  }

  onDeleteUser = (email) => {
    let self = this;
    this.props.context.interAxios.delete('/user/' + email, function(response) {
      self.onGetAllUser();
    });
  }

  onEmailChange  = (event) => {
    this.setState({email: event.target.value});
  };

  onPasswordChange  = (event) => {
    this.setState({password: event.target.value});
  };






  render(){
    console.log(this.state.userlist);
    console.log(this.state.email + ", " + this.state.password);
    console.log(this.state.showUserFunction);
    let userList = {}
      userList = this.state.userlist.map(
        (k, n) => {
              return (
                <tr key={n}>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold m-0'>{k.email}</p>
                    </div>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <p className='fw-normal m-0 text-center'>{k.role}</p>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <div className="d-flex align-items-center justify-content-center">
                      <MDBCol md="auto">
                        <MDBBtn onClick={() => this.onSelectUser(k.id, true)} tag='a' color='none' className='mx-2'>
                          <MDBIcon fas icon="pen" />
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol md="auto">
                        <MDBBtn onClick={() => this.onDeleteUser(k.email)} tag='a' color='none' className='mx-2'>
                          <MDBIcon far icon="trash-alt" />
                        </MDBBtn>
                      </MDBCol>
                    </div>
                  </td>
                </tr>
              );
            }
      )
    return (
      <div className="userservice-modal">
      <MDBModal show={this.props.showModal} tabIndex='-1' >
        <MDBModalDialog size='xl' scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="d-flex align-items-center justify-content-center pt-5"><img src={danfossLogo}/></MDBModalTitle>
              <MDBBtn type="btn" className='save' onClick={() => this.onSelectUser("", false)}>Create User</MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow style={{paddingLeft: "6%", maxWidth: "90%"}}>
              <MDBCol md="3" center className="d-flex justify-content-start p-0">
              <span className="service-title">User service</span>
              </MDBCol>
              <MDBCol md="9">
              <MDBTable align='middle' responsive small>
                <MDBTableHead>
                  <tr>
                    <th scope='col' style={{width: "60%", padding: "0.5rem"}}>Email</th>
                    <th scope='col' className='text-center' style={{width: "20%", padding: "0.5rem"}}>Role</th>
                    <th scope='col' className='text-center' style={{width: "20%", padding: "0.5rem"}}>Actions</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {userList}
                </MDBTableBody>
              </MDBTable>
              </MDBCol>
              </MDBRow>
              {this.state.showUserFunction ?
                      <div className="d-flex align-items-start py-3 px-4">
                        <MDBRow style={{paddingLeft: "26%", maxWidth: "65%"}}>
                          <MDBCol md="12" size="12" sm="12" className="pb-3">
                            <MDBInput
                              value={this.state.email}
                              name='email'
                              type="text"
                              onChange={this.onEmailChange}
                              label='Email'
                            />
                          </MDBCol>
                          <MDBCol md="12" size="12" sm="12">
                            <MDBInput
                              value={this.state.password}
                              name='password'
                              id='typePassword'
                              type="password"
                              onChange={this.onPasswordChange}
                              label='Password'
                            />
                          </MDBCol>
                          <MDBCol size="12" className="py-3 d-flex justify-content-end" >
                          {this.state.email !== "" && this.state.password !== "" ? <>
                            <MDBBtn type='btn' className="cancel" onClick={this.onCancelUser}>Cancel</MDBBtn>
                            <MDBBtn type='btn' className="save" onClick={!this.state.updateFunktion ? this.onCreateUser : this.onUpdateUser}>Send</MDBBtn></>:<>
                            <MDBBtn type='btn' className="cancel" onClick={this.onCancelUser}>Cancel</MDBBtn>
                            <MDBBtn type='btn'>Save</MDBBtn></>}
                          </MDBCol>
                        </MDBRow>
                      </div>:false}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="btn" className="save" onClick={this.props.hideModal}>Close</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>

    );
  }
}
export default function UserServiceModalFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <UserServiceModal context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}
                     showModal = {props.showModal} hideModal = {props.hideModal}/>;
}

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import ModalTemplate from "../ModalTemplate";
import AppContext from '../../../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect
} from 'mdb-react-ui-kit';


class DeleteModal extends Component {

	constructor(props) {
    super(props);
    this.state = {
      btnForm: [  {classname: "cancel" , onclick: this.props.hideModal , text: "Cancel"},
                  {classname: "save" , onclick: this.internalHandleSave , text: "Delete"}
               ]
    };
  }

  componentDidMount() {

  }

  internalHandleSave = () => {
    this.props.onDeleteProduct(this.props.productId);
    this.props.hideModal();
  }


	render() {

    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={this.state.btnForm}
          modalId={""}
  			>
        {this.props.textend}

      </ModalTemplate>
    );
  }
}
export default DeleteModal;

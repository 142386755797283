import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import ModalTemplate from "./ModalTemplate";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
  MDBInput,
  MDBRow,
  MDBAlert
} from 'mdb-react-ui-kit';


class FooterContentEditModal extends Component {

	constructor(props) {
    super(props);
    this.state = {name: "", link: "",
                  btnForm: [  {classname: "cancel" , onclick: this.props.hideModal , text: "cancel"},
                              {classname: "save" , onclick: this.internalHandleSave , text: "Save"}]}
  }

  componentDidMount() {
    if(this.props.footerId !== undefined && this.props.footerComponentId !== undefined){
      this.onGetFooterById(this.props.footerId);
    }
  }

  onGetFooterById = (id) => {
    let footeriId = parseInt(this.props.footerComponentId)
    let self = this;
    let name ="";
    let url="";
    this.props.context.interAxios.get('/footer/' + id, function(response) {
      for(let i=0; i<response.data.content.length; i++){
        if(response.data.content[i].id === footeriId){
          name=response.data.content[i].title,
          url=response.data.content[i].url
        }
      }

      self.setState({
        name: name,
        link: url
      });
    })
  }


  internalHandleSave = () => {
    if(this.state.name !== "" && this.state.link !== ""){
      if(this.props.footerComponentId !== undefined){
        this.props.onUpdateFooterComponent(this.props.footerComponentId, this.state.name, this.state.link);
      }else{
        this.props.onCreateNewFooterComponent(this.props.footerId, this.state.name, this.state.link);
      }

      this.props.hideModal();
    }else{
      this.setState({alert: true, alertText:"Please fill in all fields"})
    }

  }

  onNameChange = (event) => {
    this.setState({name: event.target.value});
  }

  onLinkChange = (event) => {
    this.setState({link: event.target.value});
  }

	render() {
    let self = this;
    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={this.state.btnForm}
          modalId={""}
          showModal={this.props.showFooterComponentEditModal}
          hideModal={this.props.hideModal}
  			>
        <MDBRow className="pb-3">
          <span className="section-submneu-text">Select existing document</span>
            <div style={{maxWidth: "300px"}} className="py-3">
              <MDBSelect
                id="software_section"
                data={this.props.data}
                label='Example label'
                onValueChange={(e) => this.onDocumentFromListChange(e)}
              />
            </div>
        </MDBRow>
        <MDBRow className="pb-3">
          <span>Footer component name<span style={{color: "red"}}>*</span></span>
          <MDBInput id='name'
                    type='text'
                    value= {this.state.name}
                    onChange={this.onNameChange} />
        </MDBRow>
        <MDBRow className="pb-3">
          <span>Link<span style={{color: "red"}}>*</span></span>
          <MDBInput id='link'
                    type='text'
                    value= {this.state.link}
                    onChange={this.onLinkChange} />
        </MDBRow>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >{this.state.alertText}</MDBAlert>
      </ModalTemplate>
  );
  }
}
export default FooterContentEditModal;

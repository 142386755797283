import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;

import ModalTemplate from "../ModalTemplate";
import CreateImageCategory from "./CreateImageCategory";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

class EditModal extends Component {

	constructor(props) {
    super(props);
    this.state = {description: this.props.description,
                  title: this.props.categoryName,
                };
  }

  internalHandleSave = () => {
    if(this.state.title!=="" && this.props.modalTyp==="Add new category"){
      this.props.onNewCategory(this.state.title, this.state.description)
      console.log("itt")
    }
    if(this.state.title!=="" && this.props.modalTyp==="Category settings"){
      this.props.onUpdateCategory(this.state.title, this.state.description, this.props.categoryId)
    }

    this.props.hideModal();
  }

  onDescriptionChange = (description) => {
    this.setState({description: description});
	}

  onTitleChange = (event) => {
    this.setState({title: event.target.value});
  }


	render() {
    console.log(this.props.modalTyp);
    let btnForm = [  {classname: "cancel" , onclick: this.props.hideModal , text: "Cancel"},
                {classname: "save" , onclick: this.internalHandleSave , text: "Save"}
             ];
    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={btnForm}
          modalId={""}
  			>
        <span className="section-submneu-text">Category image</span><br/>
        <MDBRow className="m-0 pb-3">
          <MDBCol md="4" className="d-flex align-items-center justify-content-center px-0">
            <img src={this.props.categoryUrl} style={{width: "120px"}}/>
          </MDBCol>
          <MDBCol md="6">
            <MDBRow className="m-0">
              <span className="add-normal-text px-0 pb-2">Upload tumbnail image for your category!</span>
            </MDBRow>
            <MDBRow className="m-0">
              <MDBBtn type="btn" className="change-image-btn" onClick={() => this.props.handleShowBackground(this.props.categoryId, this.state.title, this.state.description)}>Change image</MDBBtn>
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <span className="section-submneu-text">Category name</span>

        <MDBInput
                      wrapperClass='my-2'
                      type='text'
                      id='title'
                      value={this.state.title}
                      onChange={this.onTitleChange}/>
        <div className="ckeditor-padding">
          <span className="section-submneu-text">Category description<span className="add-normal-text"> (400 character)</span></span>
  				<CKEditor
          	editor={ ClassicEditor }
  					data={this.state.description}
  					config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
          	onChange={
  						( event, editor ) => {
          			const data = editor.getData();
          			this.onDescriptionChange(data);
          		}
  					}
          />
				</div>
      </ModalTemplate>
  );
  }
}
export default EditModal;

import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBRow,
         MDBCol,
         MDBBtn
       } from 'mdb-react-ui-kit';

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <>{!props.url.trashBin ?
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="d-flex align-items-center justify-content-center padding-margin-setup">
    <div className="photo-area">
      <img src={props.url.url} style={{opacity: props.isDeleteFunction ? "0.6" : "1"}}/>
    </div>
    {props.isDeleteFunction ? <>
    <div className="delete-btn d-flex align-items-center justify-content-center">
      <MDBBtn onClick={() => props.onRemove(props.ertek)}><img src={trash}/></MDBBtn>
    </div></>:false}
    </div>:false}</>
  );

}

export default SortableItem

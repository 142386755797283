import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple
} from 'mdb-react-ui-kit';
import question from "../../assets/media/logo/Danfoss-logo.png";

class ViewCard extends Component {

  constructor(props) {
    super(props);
    //this.state = {};

  }

  truncateString = (str, num) => {
		if(str !== null){
			if (str.length <= num) {
				return str
	  	}
	  			return str.slice(0, num) + '...'
		}
	}


  render(){
    return (
      <MDBCard className="viewcard">
        <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
        {this.props.url === "" ?
        <div className="quetion-card-products d-flex align-items-center justify-content-center">
            <img src={question}/><br/>
        </div>:<>
          <MDBCardImage src={this.props.url} fluid alt='...' />

            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
          </>}
        </MDBRipple>
        <MDBCardBody>
          <MDBCardTitle><span className="title-text">{this.props.title}</span></MDBCardTitle>
          <MDBCardText>
            <span className="detail-text" dangerouslySetInnerHTML={{__html: this.truncateString(this.props.description, 200)}} />
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default ViewCard

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import Moment from 'moment';
import 'moment/locale/de';
import DeleteModal from '../modals/categories/DeleteModal.js';

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";

import { MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBSwitch
       } from 'mdb-react-ui-kit';

class ProductsItem extends Component {

  constructor(props) {
    super(props);
    this.state = {itemsList: [],
                  showModal: false,
                  productId:"",
                  productName: "",
                 }
  }

  componentDidMount() {
    this.onGetAllProduct(this.props.searchParams.get("categoryId"));
  }

  onGetAllProduct = (id) => {
    let self = this;
    this.props.context.interAxios.get('/category/' + id + "/product-summary", function(response) {
      self.setState({itemsList: response.data});
    })
  }

  backKategories = () => {
    this.props.navigate("/admin/products");
  }

  onShowDetail = (index, id, productName, urlName) => {
    let itemsCount = this.state.itemsList.filter(k => k.status && k.available).length;
    const searchParams = new URLSearchParams();
    searchParams.append("categoryId", this.props.searchParams.get("categoryId"));
    searchParams.append("categoryName", this.props.searchParams.get("categoryName"));
    searchParams.append("productId", id);
    searchParams.append("productName", productName);
    searchParams.append("itemsCount", itemsCount);
    searchParams.append("index", index);
    this.props.navigate("/admin/productsdetail?" + searchParams.toString());
  };

  showDeleteProduct = (id, productName) => {
    let deleteText = <span className="deletemodal-text">Do you want to delete your "
                      <b>{productName}</b>
                      " product?</span>;
    this.setState({showModal: true, productId: id, productName: productName, status: "Delete product", text: deleteText})
  };
  onDeleteProduct = (id) => {
    let self = this;
    this.props.context.interAxios.delete('/product/' + id , function(response) {
      self.onGetAllProduct(self.props.searchParams.get("categoryId"));
    })
  }

  hideProduct = () => {
    this.setState({showModal: false, productId: "", productName: ""})
  }

  handleSwitchChange = id => {
    let self = this;
    for(let i = 0; i < this.state.itemsList.length; i++){
      if(this.state.itemsList[i].id == id){
        this.props.context.interAxios.put('/product/' + id + '/status', !this.state.itemsList[i].status, { headers: {'Content-Type': 'application/json'}},
         function(response) {
          self.onGetAllProduct(self.props.searchParams.get("categoryId"));
        })
      }
    }
	};

  handleSwitchDiscontinuedChange = id => {
    let self = this;
    for(let i = 0; i < this.state.itemsList.length; i++){
      if(this.state.itemsList[i].id == id){
        this.props.context.interAxios.put('/product/' + id + '/available', !this.state.itemsList[i].available, { headers: {'Content-Type': 'application/json'}},
         function(response) {
          self.onGetAllProduct(self.props.searchParams.get("categoryId"));
        })
      }
    }
	};


  render(){
    let itemsLis = {}
      itemsLis = this.state.itemsList.map(
        (k, n) => {
              return (
                <tr key={n} style={{height: "70px"}}>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 1.7rem"}}>
                    <p className='title-bold m-0'>{k.title}</p>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <div className="d-flex align-items-center justify-content-center">
                    <MDBSwitch key={n} id="chatfilter" className="form-row" checked={k.status} onChange={() => this.handleSwitchChange(k.id)}
                    	label={
                    					<span >{k.status ? "Active" : "Inactive"}</span>
                    	}/>
                    </div>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <div className="d-flex align-items-center justify-content-center">
                    <MDBSwitch key={n} id="discontinuedfilter" className="form-row" checked={k.available} onChange={() => this.handleSwitchDiscontinuedChange(k.id)}
                    	label={
                    					<span >{k.available ? "Active" : "Inactive"}</span>
                    	}/>
                    </div>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <p className='fw-normal m-0 text-center'>{Moment(k.lastEdit).format('YYYY-MM-DD')}</p>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <div className="d-flex align-items-center justify-content-center">
                      <MDBBtn onClick={() => this.onShowDetail(n, k.id, k.title)} tag='a' color='none' className='mx-2'>
                        <img src={edit}/>
                      </MDBBtn>
                    </div>
                  </td>
                  <td style={{padding: "0.4rem 0.5rem 0.4rem 0.5rem"}}>
                    <div className="d-flex align-items-center justify-content-center">
                      <MDBBtn onClick={() => this.showDeleteProduct(k.id, k.title)} tag='a' color='none' className='mx-2'>
                        <img src={trash}/>
                      </MDBBtn>
                    </div>
                  </td>
                </tr>
              );
            }
      )
    return (
      <div style={{width: "100%"}} className="px-2">
      <MDBRow className="pt-5">
        <MDBCol center lg="1" sm="2" className="padding-col">
          <MDBBtn type="btn" onClick={this.backKategories} className="transparent-btn">
            <div className="d-flex align-items-center justify-content-center">
              <img src={arrowLeft}/>
            </div>
          </MDBBtn>
        </MDBCol>
        <MDBCol center lg="5" sm="5" className="margin-col">
        <h2 className="title-text">{this.props.searchParams.get("categoryName")}</h2>
        <span className="url-text">{"Products - " + this.props.searchParams.get("categoryName")}</span>
        </MDBCol>
        <MDBCol center lg="6" sm="5" className="col-btn">
            <MDBBtn type="btn" onClick={() => this.onShowDetail(-1, "", "", this.props.searchParams.get("urlName"))} className="danfoss-btn admin">
              <div className="d-flex align-items-center justify-content-center">
                <img src={addSmall}/><span> Add new product</span>
              </div>
            </MDBBtn>
        </MDBCol>
      </MDBRow>
      <div className="d-flex align-items-center justify-content-center items-table pt-4">
        <MDBTable align='middle' responsive small>
          <caption>
            <MDBBtn className="table-footer" onClick={() => this.onShowDetail(-1, "", "", this.props.searchParams.get("urlName"))} >
              <img src={addSmall}/><span> Add new product</span>
            </MDBBtn>
          </caption>
          <MDBTableHead className="table-header">
            <tr>
              <th scope='col' className='text-start' style={{width: "25%", paddingLeft: "1.7rem"}}>PRODUCT TITLE</th>
              <th scope='col' className='text-center' style={{width: "15%", padding: "0.5rem"}}>STATUS</th>
              <th scope='col' className='text-center' style={{width: "15%", padding: "0.5rem"}}>DISCONTINUED</th>
              <th scope='col' className='text-center' style={{width: "15%", padding: "0.5rem"}}>LAST EDIT</th>
              <th scope='col' className='text-center' style={{width: "15%", padding: "0.5rem"}}>EDIT</th>
              <th scope='col' className='text-center' style={{width: "15%", padding: "0.5rem"}}>DELETE</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {itemsLis}
          </MDBTableBody>
        </MDBTable>
      </div>
      {this.state.showModal ?
      <DeleteModal hideModal={this.hideProduct}
                   showModal={this.state.showModal}
                   modalTyp={this.state.status}
                   productId={this.state.productId}
                   onDeleteProduct={this.onDeleteProduct}
                   textend={this.state.text}/> : false}
      </div>
    );
  }
}
export default function ProductsItemFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    return <ProductsItem context={context} searchParams={searchParams} navigate={navigate} location={location} />;
}

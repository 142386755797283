import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext'

import ProductsFromCategory from '../OtherComponents/ProductsFromCategory'

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';

class ProductNavbarModal extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  }


	render() {
    let array = [];
    array = this.props.categorisLis.filter((k,n) => (n<4)).map((k,n) => {
      return(
        <MDBCol md="12" key={n}>
          <Link className="link-categories"
                to={"/categorypage?categoryId=" + k.id}
                onClick={() => this.props.productSetBasicModal(false)}
                >
            {k.title}
          </Link>
          {/*<div className="mt-2">
            <ProductsFromCategory categoryId={k.id} productSetBasicModal={this.props.productSetBasicModal}/>
          </div>*/}
        </MDBCol>
      )
    })
		return (
      <MDBModal id={!this.props.navbarPaddingTop ? "productWithScroll" : "product"} show={this.props.productBasicModal}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalBody>
              <MDBRow>
              {array}
                <MDBCol md="12">
                  <Link className="link-categories"
                        to="/categorylistpage"
                        onClick={() => this.props.productSetBasicModal(false)}
                        >
                    Other
                  </Link>
                  {/*<div className="mt-2">
                    <ProductsFromCategory categoryId={k.id} productSetBasicModal={this.props.productSetBasicModal}/>
                  </div>*/}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
		);
	}
}
export default function ProductNavbarModalFunc(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    return <ProductNavbarModal context={context}
                               navigate={navigate}
                               categorisLis={props.categorisLis}
                               productBasicModal={props.productBasicModal}
                               productSetBasicModal={props.productSetBasicModal}
                               navbarPaddingTop={props.navbarPaddingTop}/>;
}

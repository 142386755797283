import React, { Component, useContext  } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import addSmall from "../../../assets/media/icons/Add Small.svg";
import danfossLogo from "../../../assets/media/logo/Danfoss logo.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";

import { MDBRow,
         MDBCol,
         MDBBtn,
         MDBModal,
         MDBModalDialog,
         MDBModalContent,
         MDBModalHeader,
         MDBModalTitle,
         MDBModalBody,
         MDBModalFooter,
         MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBInput,
         MDBIcon,
         MDBSpinner,
         MDBCard
       } from 'mdb-react-ui-kit';



class VideoUploadModal extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {videoList: []}

  }

  componentDidMount() {
    this.onGetAllVideo();
  }

  onGetAllVideo = () => {
    let self = this;
    this.props.context.interAxios.get('/video', function(response) {

      self.setState({videoList: response.data});
    })
  }

  handleChoose = (event) => {
    this.inputRef.current.click();
  };

  handleVideoFileChange = (event) => {
    if(event.target.files[0].size < 200000000){
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      this.setState({videoName: file.name, selectedVideoFile: file})
    }else {
      alert(alert("Video size not correct. Please add at least 150MB video."))
    }
    
  };

  onVideoNameChange = (event) => {
    this.setState({videoName: event.target.value});
  }

  onFileUpload = () => {
    this.setState({spinner: true});
    if(this.state.selectedVideoFile!==undefined){
      let self = this;
      const formData = new FormData
      formData.append("file", this.state.selectedVideoFile);
      formData.append("name", this.state.videoName);
      this.props.context.interAxios.post('/video', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
        self.setState({spinner: false});
        self.onGetAllVideo();
        self.hideShowUpload();
      });
    }else{
      alert("Choose a Video file!");
    }
  }

  onDeleteVideo = (id) => {
    let self = this;
    this.props.context.interAxios.delete('/video/' + id, function(response) {
      self.onGetAllVideo();
    })
  }


  onShowUpload = () => {
    this.setState({showUpload: true, videoName: ""})
  }

  hideShowUpload = () => {
    this.setState({showUpload: false, videoName: "", selectedVideoFile: undefined})
  }

  render(){
    let data = this.state.videoList.map((k,n) => {
      return (
        <tr key={n}>
          <td>
            <div className='d-flex align-items-center'>
               <p className='fw-bold mb-1'>{k.name}</p>
            </div>
          </td>
          <td>
            <MDBBtn tag='a' color='none' className='mx-2' onClick={() => this.onDeleteVideo(k.id)}>
              <img src={trash}/>
            </MDBBtn>
          </td>
        </tr>
      )
    })


    return (
      <div className="userservice-modal">
      <MDBModal show={this.props.showModal} tabIndex='-1' >
        <MDBModalDialog size='xl' scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="pt-3">
                <MDBCol>
                  <MDBRow center className="pb-3">
                    <img src={danfossLogo} style={{width: "70%"}}/>
                  </MDBRow>
                  <MDBRow center>
                    <span  className="service-title d-flex align-items-center justify-content-center">Video upload</span>
                  </MDBRow>
                </MDBCol>
              </MDBModalTitle>
              {this.state.spinner ?
                              <div className="spinner-position">
                                <MDBSpinner /><br/>
                                <span className="">Loading...</span>
                              </div>:false}
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="pb-4 px-3">
                <MDBCol md="3" center className="d-flex justify-content-start p-0">
                </MDBCol>
                <MDBCol md="9">
                  <span className="section-submneu-text">Video list</span>
                  <div className="d-flex align-items-center justify-content-center items-table pt-4">
                    <MDBTable align='middle' responsive small>
                    <caption>
                    <div className="d-flex align-items-center justify-content-center py-3">
                      <MDBBtn className="table-footer" onClick={this.onShowUpload} >
                        <img src={addSmall}/><span> Add new video</span>
                      </MDBBtn>
                    </div>
                    </caption>
                     <MDBTableHead>
                       <tr>
                         <th scope='col'>Video Name</th>
                         <th scope='col'>Actions</th>
                       </tr>
                     </MDBTableHead>
                     <MDBTableBody>
                       {data}
                     </MDBTableBody>
                    </MDBTable>
                  </div>
                </MDBCol>
              </MDBRow>
              {this.state.showUpload ?
              <MDBRow className="pb-4 pt-2 px-3">
                <MDBCol md="3" center className="d-flex justify-content-start p-0">
                </MDBCol>
                <MDBCol md="9">
                  <MDBCard className="fileupdate-card">
                    <input
                      ref={this.inputRef}
                      className="VideoInput_input"
                      type="file"
                      onChange={this.handleVideoFileChange}
                      accept=".mov,.mp4"
                    />
                    <MDBRow center>
                      <MDBBtn type="btn" className="upload" onClick={this.handleChoose}><MDBIcon fas icon="upload" size="3x"/></MDBBtn>
                    </MDBRow>

                    <MDBRow center className="pt-4">
                      <span className="section-submneu-text d-flex justify-content-center">Video name</span>
                      <div style={{width: "80%"}}>
                        <MDBInput label='Video name' id='form1' type='videoName' value={this.state.videoName} onChange={this.onVideoNameChange}/>
                      </div>
                    </MDBRow>
                    <MDBRow end className="pt-2">
                      <MDBBtn type="btn" className="cancel" onClick={this.hideShowUpload}>Cancel</MDBBtn>
                      <MDBBtn type="btn" className="save" onClick={this.onFileUpload}>Upload</MDBBtn>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              :false}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBRow className="px-3">
                <MDBBtn type="btn" className="save" onClick={this.props.hideModal}>Close</MDBBtn>
              </MDBRow>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>

    );
  }
}
export default function VideoUploadModalFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <VideoUploadModal context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}
                     showModal = {props.showModal} hideModal = {props.hideModal}/>;
}

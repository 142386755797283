import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';

import danfossLogo from "../../../assets/media/logo/Danfoss logo.svg";
import productsIcon from "../../../assets/media/logo/Products Icon.svg";
import otherpagesIcon from "../../../assets/media/logo/Other Pages Icon.svg";
import logoutIcon from "../../../assets/media/logo/Logout Copy.svg";
import service from "../../../assets/media/icons/Service.svg";
import user_service from "../../../assets/media/icons/Users service.svg";

import UsersServiceModal from "../modals/UserServiceModal.js"
import FileUploadModal from "../modals/FileUploadModal.js"
import VideoUploadModal from "../modals/VideoUploadModal.js"
import DocumentUploadModal from "../modals/DocumentUploadModal.js"

import {
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon
} from 'mdb-react-ui-kit';

class AdminNavbar extends Component {

	constructor(props) {
    super(props);
    let textArray = this.props.location.pathname.match("otherpages");
    this.state = {activeItem : textArray !== null ? "OTHER PAGES" : "PRODUCTS"};
  }

  toggle = tab => e => {

    if (tab === "PRODUCTS") {
			this.setState({
				activeItem: "PRODUCTS"});
		}
    if (tab === "OTHER PAGES") {
			this.setState({
				activeItem: "OTHER PAGES"});
		}
    if (tab === "FOOTER COMPONENT") {
			this.setState({
				activeItem: "FOOTER COMPONENT"});
		}

	}

  onLogout = () => {
    this.props.context.onLogout();
    this.props.navigate("/admin");
  }

  showUserservice = () => {
    this.setState({showUserservice: this.props.context.isSuperAdmin ? true : false})
  }

  hideUserservice = () => {
    this.setState({showUserservice: false})
  }

  showFileUpload = () => {
    this.setState({showFileUpload: true})
  }

  hideFileUpload = () => {
    this.setState({showFileUpload: false})
  }

  showVideoUpload = () => {
    this.setState({showVideoUpload: true})
  }

  hideVideoUpload = () => {
    this.setState({showVideoUpload: false})
  }

  showDocumentUpload = () => {
    this.setState({showDocumentUpload: true})
  }

  hideDocumentUpload = () => {
    this.setState({showDocumentUpload: false})
  }


  render() {
    return (
      <>
        <MDBCollapse show={true} tag="nav" className="d-lg-block bg-white sidebar" style={{height: "100%"}}>
          <div id="adminLandingPage" className="position-sticky"  style={{height: "100%o"}}>
          <div className="danfoss-logo d-flex align-items-center justify-content-center">
            <img src={danfossLogo}/>
          </div>
          {!this.props.isLoginPage ? <>
            <MDBListGroup>
              <MDBRipple rippleTag='span' >
                <MDBListGroupItem action onClick={this.toggle("PRODUCTS")} active={this.state.activeItem === "PRODUCTS"} aria-current={this.state.activeItem === "PRODUCTS"}>
                  <div className="navbar-text">
                    <Link to="/admin/products">
                    <MDBRow center className="row-height">
                      <img src={productsIcon}/>
                    </MDBRow>
                    <MDBRow center>
                      <span>PRODUCTS</span>
                    </MDBRow>
                    </Link>
                  </div>
                </MDBListGroupItem>
              </MDBRipple>

              <MDBRipple rippleTag='span'>
                <MDBListGroupItem action onClick={this.toggle("OTHER PAGES")} active={this.state.activeItem === "OTHER PAGES"} aria-current={this.state.activeItem === "OTHER PAGES"}>
                  <div className="navbar-text">
                    <Link to="/admin/otherpages">
                      <MDBRow center className="row-height">
                        <img src={otherpagesIcon}/>
                      </MDBRow>
                      <MDBRow center>
                        <span>OTHER PAGES</span>
                      </MDBRow>
                    </Link>
                  </div>
                </MDBListGroupItem>
              </MDBRipple>

              <MDBRipple rippleTag='span'>
                <MDBListGroupItem action onClick={this.toggle("FOOTER COMPONENT")} active={this.state.activeItem === "FOOTER COMPONENT"} aria-current={this.state.activeItem === "FOOTER COMPONENT"}>
                  <div className="navbar-text">
                    <Link to="/admin/footercomponent">
                      <MDBRow center className="py-3">
                        <MDBIcon fas icon="city" size="2x" />
                      </MDBRow>
                      <MDBRow center>
                        <span>FOOTER COMPONENT</span>
                      </MDBRow>
                    </Link>
                  </div>
                </MDBListGroupItem>
              </MDBRipple>
            </MDBListGroup>
            <div className="navbar-text pt-5">
            <MDBRow>
              <MDBCol md="12" className="d-flex align-items-center justify-content-center justify-content-center">
                <MDBBtn type="btn" className="userservice-btn" onClick={this.showFileUpload}>
                  <MDBRow center className="row-height">
                    <MDBIcon fas icon="upload" size="3x"/>
                  </MDBRow>
                  <MDBRow center className="pt-2">
                    <span>SOFTWARE</span>
                  </MDBRow>
                </MDBBtn>
              </MDBCol>
              <MDBCol md="12" className="d-flex align-items-center justify-content-center justify-content-center pt-5">
                <MDBBtn type="btn" className="userservice-btn" onClick={this.showVideoUpload}>
                  <MDBRow center className="row-height">
                    <MDBIcon fas icon="upload" size="3x"/>
                  </MDBRow>
                  <MDBRow center className="pt-2">
                    <span>VIDEO UPLOAD</span>
                  </MDBRow>
                </MDBBtn>
              </MDBCol>
              <MDBCol md="12" className="d-flex align-items-center justify-content-center justify-content-center pt-5">
                <MDBBtn type="btn" className="userservice-btn" onClick={this.showDocumentUpload}>
                  <MDBRow center className="row-height">
                    <MDBIcon fas icon="upload" size="3x"/>
                  </MDBRow>
                  <MDBRow center className="pt-2">
                    <span>DOCUMENT UPLOAD</span>
                  </MDBRow>
                </MDBBtn>
              </MDBCol>
            {this.props.context.isSuperAdmin() ?
              <MDBCol md="12" className="d-flex align-items-center justify-content-center justify-content-center pt-5">
                <MDBBtn type="btn" className="userservice-btn" onClick={this.showUserservice}>
                  <MDBRow center className="row-height">
                    <img src={user_service}/>
                  </MDBRow>
                  <MDBRow center className="pt-2">
                    <span>ADMINS</span>
                  </MDBRow>
                </MDBBtn>
              </MDBCol>: false}
              <MDBCol md="12"  className="d-flex align-items-center justify-content-center justify-content-center pt-5">
                <MDBBtn type="btn" className="logout-btn" onClick={this.onLogout}>
                  <MDBRow center className="row-height">
                    <img src={logoutIcon}/>
                  </MDBRow>
                  <MDBRow center className="pt-2">
                    <span>LOGOUT</span>
                  </MDBRow>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            </div>
            </>:false}
          </div>
        </MDBCollapse>
        {this.state.showUserservice ?
          <UsersServiceModal
              showModal= {this.state.showUserservice}
              hideModal = {this.hideUserservice}/>:false}
        {this.state.showFileUpload ?
          <FileUploadModal
              showModal= {this.state.showFileUpload}
              hideModal = {this.hideFileUpload}/>:false}
        {this.state.showVideoUpload ?
          <VideoUploadModal
              showModal= {this.state.showVideoUpload}
              hideModal = {this.hideVideoUpload}/>:false}
        {this.state.showDocumentUpload ?
          <DocumentUploadModal
              showModal= {this.state.showDocumentUpload}
              hideModal = {this.hideDocumentUpload}/>:false}
      </>
    );
  }

}
export default function AdminNavbarFunc(props) {
  const context = useContext(AppContext);
  const [showShow, setShowShow] = useState(false);
  const toggleShow = () => setShowShow(!showShow);
  const navigate = useNavigate();
  let location = useLocation();
    return <AdminNavbar showShow={showShow} location={location} setShowShow={setShowShow} toggleShow={toggleShow} context={context} navigate={navigate} isLoginPage={props.isLoginPage}/>;
}

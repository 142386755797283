import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import trash from "../../../assets/media/icons/Trash-red.svg";
import link from "../../../assets/media/icons/videolink.png";

import {
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBSelect
} from 'mdb-react-ui-kit';

class SoftwareSection extends Component {

  constructor(props) {
    super(props);
    this.state = {data: [], descriptionUpdate: "", nameUpdate: "", softwareId: 0};
  }

  componentDidMount() {
    this.onGetAllSoftware();
  }

  onGetAllSoftware = () => {
    let self = this;
    let existingArray = this.props.softwareList.map(k => k.id);
    this.props.onSoftwarelist(existingArray);
    this.props.context.interAxios.get('/software', function(response) {
      let data = [];
      if(response.data !== undefined){
        self.props.onGetSoftwareList(response.data);
        data = response.data.map(k => ({text: k.name, value: k.id, defaultSelected: existingArray.includes(k.id)}));
      }
      let updateData = [{text: "Default", value: 0, defaultSelected: true}]
      let finalUpdateData = updateData.concat(data);
      self.setState({softwareList: response.data, data: data, updateData: finalUpdateData, isUpdate: false});
    })
  }

  onNewSoftwareFromOutside = () => {
    if(this.state.url !== undefined && this.state.name !== undefined){
      let self = this;
      this.props.context.interAxios.post('/software/url', {url: this.state.url, name: this.state.name, description: this.state.description }, function(response) {
          let software = [response.data.id];
          self.props.onSoftwarelistFromOutside(software);

      })
    }else{
      alert("Fill in the corresponding fields!")
    }

  }

  onUrlChange = (event) => {
    this.setState({url: event.target.value});
	}

  onNameChange = (event) => {
    this.setState({name: event.target.value});
	}

  onArticleChange = (editorState) => {
    this.setState({description: editorState});
  }


	render() {
    return (
        <div className='py-3'>
        <MDBRow>
            <span className="add-title-text">Software section
              <MDBBtn type="btn" className="deletefunction-btn" onClick={() => this.props.deleteSoftwareSection(this.props.index)}><img src={trash}/></MDBBtn>
            </span>
        </MDBRow>

        <MDBRow className="pb-4 pt-2">
        <span className="section-submneu-text">Select existing software</span>
        <div style={{maxWidth: "300px"}} className="py-3">
          <MDBSelect
            id="software_section"
            data={this.state.data}
            multiple
            label='Example label'
            onValueChange={(e) => this.props.onSoftwarelist(e.map(k => k.value))}
          />
        </div>
        </MDBRow>
        <span className="section-submneu-text">Add software manually</span>
        <MDBBtn type="btn" className="software-btn" onClick={this.onNewSoftwareFromOutside}><img src={link}/></MDBBtn>
        <small> Before saving the product, the manually specified software must be saved!</small>
        <MDBRow>
            <span className="section-submneu-text">Software name</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='title'
                        value={this.state.name}
                        onChange={this.onNameChange}/>
          </div>
          </MDBRow>
          <MDBRow>
            <span className="section-submneu-text">Software URL</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='software'
                        value={this.state.url}
                        onChange={this.onUrlChange}/>
          </div>
          </MDBRow>
          <MDBRow>
            <span className="section-submneu-text">Description</span>
            <div style={{width: "80%"}}>
              <CKEditor
                editor={ ClassicEditor }
                data={this.state.description}
                config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
                onChange={
                  ( event, editor ) => {
                    const data = editor.getData();
                    this.onArticleChange(data);
                  }
                }
              />
            </div>
          </MDBRow>
        </div>
  )
  }
}
export default function SoftwareSectionFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <SoftwareSection context={context}
                            searchParams={searchParams}
                            navigate={navigate}
                            nodeRef={nodeRef}
                            deleteSoftwareSection={props.deleteSoftwareSection}
                            onSoftwarelist={props.onSoftwarelist}
                            onSoftwarelistFromOutside={props.onSoftwarelistFromOutside}
                            softwareList={props.softwareList}
                            onGetSoftwareList={props.onGetSoftwareList}
                            />;
}

import React, { Component, useContext, useState  } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext';
import ProductNavbarModal from '../Modals/ProductNavbarModal';

import arrowUp from "../../assets/media/logo/arrowUp.png";
import arrowDown from "../../assets/media/logo/arrowDown.png";



import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTooltip
} from 'mdb-react-ui-kit';

class NavbarComponentSubmenu extends Component {

	constructor(props) {
    super(props);
    this.state = {activeItem : "N/A", dropdownOpen: false};
  }

  productSetBasicModal = (value) => {
    this.setState({productBasicModal: value})
  }

  lastPathNameElement = () => {
    return window.location.pathname.split("/")[window.location.pathname.split("/").length-1]
  }

  truncateString = (str, num) => {
		if(str !== null){
			if (str.length <= num) {
				return str
	  	}
	  			return str.slice(0, num) + '...'
		}
	}

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onMouseEnter = () => {
    this.setState({dropdownOpen: true});
  }

  onMouseLeave = () => {
    this.setState({dropdownOpen: false});
  }

	render() {
    let shortlinkHome = this.props.homePage[0]!== undefined ? this.props.homePage[0].shortlink : "";
    let itemList = this.props.otherpagesList.filter((k,n) => (n<6)).map((k,n) =>
      {
        return (
          <MDBNavbarItem key={n}>
            <Link className={this.lastPathNameElement() === k.shortlink ? "minus-margin" : ""} to={'/page/' + k.shortlink}>
              {k.name.length>10 ?
                <MDBTooltip tag='span' wrapperProps={{ href: '#' }} placement='bottom' title={k.name}>
                      {this.truncateString(k.name, 10)}
               </MDBTooltip>:
               <>{k.name}</>}

            </Link>
            <div id="withoutScroll" className={this.lastPathNameElement() === k.shortlink ? "active-link" : ""}/>
          </MDBNavbarItem>
        );
      }
    )
    let array = [];
    array = this.props.categorisLis.filter((k,n) => (n<4)).map((k,n) => {
      return(
        <DropdownItem key={n} >
        <Link to={"/categorypage?categoryId=" + k.id}>
          {k.title}
        </Link>
        </DropdownItem>
      )
    })
		return (

      <MDBNavbar id="withoutScroll" expand='lg' light bgColor='light'  fixed='top' style={{top: this.props.context.mainOffsetHeight}}>
        <MDBContainer>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem>
              <Link className={this.lastPathNameElement() === shortlinkHome ? "minus-margin" : ""} to={'/' + shortlinkHome}>
                Home
              </Link>
              <div id="withoutScroll" className={this.lastPathNameElement() === shortlinkHome ? "active-link" : ""}/>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Dropdown onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle>
                  <Link className="nav-link" to="/categorylistpage">
                      Products <img src={this.state.productBasicModal ? arrowUp : arrowDown} className="dropdown-logo"/>
                  </Link>
                </DropdownToggle>
                <DropdownMenu>
                  {array}
                  <DropdownItem>
                  <Link
                        to="/categorylistpage">
                    Other
                  </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <div id="withoutScroll" className={this.lastPathNameElement() === "categorylistpage" || this.lastPathNameElement() === "categorypage" || this.lastPathNameElement() === "productpage" ? "active-link-drop " : ""}/>
            </MDBNavbarItem>


          {/*  <MDBNavbarItem>
                <MDBDropdown className="d-inline-block" onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.toggle}>

                  <Link className="nav-link" to="/categorylistpage" onClick={() => this.productSetBasicModal(false)}>
                      Products <img src={this.state.productBasicModal ? arrowUp : arrowDown} className="dropdown-logo"/>
                  </Link>

                  <MDBDropdownMenu>
                  {array}
                  </MDBDropdownMenu>
                </MDBDropdown>
                <div id="withoutScroll" className={this.lastPathNameElement() === "categorylistpage" || this.lastPathNameElement() === "categorypage" || this.lastPathNameElement() === "productpage" ? "active-link-drop " : ""}/>

            </MDBNavbarItem> */}

            {itemList}
            {this.props.otherpagesList.length > 5 ?
            <MDBNavbarItem>
              <Link to={'/otherpagelistpage'}>
                <span className="more-text">More</span>
              </Link>
            </MDBNavbarItem>:false}

          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
		);
	}
}

export default function NavbarComponentSubmenuFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    return <NavbarComponentSubmenu navbarPaddingTop={props.navbarPaddingTop}
                                   location={location}
                                   context={context}
                                   homePage={props.homePage}
                                   otherpagesList={props.otherpagesList}
                                   categorisLis={props.categorisLis}/>;
}

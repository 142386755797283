import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import ViewCard from '../components/Cards/ViewCard.js'

import addSmall from "../assets/media/icons/Add Small.svg";
import arrowLeft from "../assets/media/icons/arrow-circle-left.png";
import edit from "../assets/media/icons/Edit.svg";
import trash from "../assets/media/icons/Trash-red.svg";
import addNew from "../assets/media/icons/Add New.svg";
import question from "../assets/media/logo/Danfoss-logo.png";

import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBNavbar,
         MDBNavbarNav,
         MDBNavbarItem,
         MDBNavbarLink
       } from 'mdb-react-ui-kit';


class SearchPage extends Component {

  constructor(props) {
    super(props);
    this.listInnerRef = React.createRef();
    this.state = {otherpageLis: [],
                  productsLis: [],
                  categoryLis: [],
                  activeItem: 0,
                  navbaritems: [],
                  searchText: this.props.searchParams.get("searchText"),
                  itemsList: [],
                isAllResults: false};
  }

  componentDidMount() {
    this.onGetSearch(this.props.searchParams.get("searchText"));
  }

  componentDidUpdate(prewProps, prewState){
    if(this.state.searchText !== this.props.searchParams.get("searchText")){
      this.setState({searchText: this.props.searchParams.get("searchText")});
      this.onGetSearch(this.props.searchParams.get("searchText"));
      this.listInnerRef.current.scrollIntoView({
     });
    }
    if(this.state.isAllResults === true){
      this.onAllResults(0);
    }

  }

  onGetSearch = (searchtext) => {
    if(searchtext !== ""){
      let self = this;
      this.props.context.interAxios.get('/search', { headers: {'Content-Type': 'text'},
                                                     params: {q: searchtext}},  function(response) {

         let allCount = response.data.categories.filter(k => k.foundIn.length > 0).length +
                      response.data.products.filter(k => k.foundIn.length > 0).length +
                      response.data.otherpages.filter(k => k.foundIn.length > 0).length;
         let newNavbaritems = [{value: "All results (" + allCount + ")", funktion: self.onAllResults}];
         let titleCount = 0;
         let nameCount = 0;
         let descriptionCount = 0;
         let descriptionTitleCount = 0;
         let textSectionTypeCount = 0;
         let documentSectionTypeCount = 0;
         let linkSectionTypeCount = 0;
         let videoSectionTypeCount = 0;

         if(response.data.categories.filter(k => k.foundIn.length > 0).length > 0){
           let categoriesCount = response.data.categories.filter(k => k.foundIn.length > 0).length;
           newNavbaritems.push({value: "Categories (" + categoriesCount + ")", funktion: self.onCategory});
         }
         if(response.data.otherpages.filter(k => k.foundIn.length > 0).length > 0){
           let otherpagesCount = response.data.otherpages.filter(k => k.foundIn.length > 0).length;
           newNavbaritems.push({value: "Other Pages (" + otherpagesCount + ")", funktion: self.onOtherPage});
         }
         if(response.data.products.filter(k => k.foundIn.length > 0).length > 0){
           let productsCount =response.data.products.filter(k => k.foundIn.length > 0).length;
           newNavbaritems.push({value: "Products (" + productsCount + ")", funktion: self.onProducts});

         let arrayProductsWithoutFilter = response.data.products.filter(k => k.foundIn.length > 0)

         for(let i=0; i<arrayProductsWithoutFilter.length; i++){
           let arrayWithoutMap = arrayProductsWithoutFilter[i].foundIn;
           if(arrayWithoutMap.map((k) => ({sectionType: k.sectionType})).filter(k => k.sectionType=== "TEXT").length>0){
               textSectionTypeCount += 1;
           }
           if(arrayWithoutMap.map((k) => ({sectionType: k.sectionType})).filter(k => k.sectionType=== "LINK").length>0){
               linkSectionTypeCount += 1;
           }
           if(arrayWithoutMap.map((k) => ({sectionType: k.sectionType})).filter(k => k.sectionType=== "DOCUMENT").length>0){
               documentSectionTypeCount += 1;
           }
           if(arrayWithoutMap.map((k) => ({sectionType: k.sectionType})).filter(k => k.sectionType=== "VIDEO").length>0){
               videoSectionTypeCount += 1;
           }

           let array = arrayWithoutMap.filter(k => k.id === undefined);
           for(let j=0; j<array.length; j++){
             if(array[j] === "title"){
               titleCount ++;
             }
             if(array[j] === "name"){
               nameCount ++;
             }
             if(array[j] === "description"){
               descriptionCount ++;
             }
             if(array[j] === "descriptionTitle"){
               descriptionTitleCount ++;
             }
           }
         }

           if(titleCount > 0){
             newNavbaritems.push({value: "Title (" + titleCount + ")", funktion: self.onTitle});
           }
           if(nameCount > 0){
             newNavbaritems.push({value: "Name (" + nameCount + ")", funktion: self.onName});
           }
           if(descriptionCount > 0){
             newNavbaritems.push({value: "Description (" + descriptionCount + ")", funktion: self.onDescription});
           }
           if(descriptionTitleCount > 0){
             newNavbaritems.push({value: "Description Title (" + descriptionTitleCount + ")", funktion: self.onDescriptionTitle});
           }
           if(textSectionTypeCount > 0){
             newNavbaritems.push({value: "Related text (" + textSectionTypeCount + ")", funktion: self.onTextSection});
           }
           if(linkSectionTypeCount > 0){
             newNavbaritems.push({value: "Product range (" + linkSectionTypeCount + ")", funktion: self.onLinkSection});
           }
           if(documentSectionTypeCount > 0){
             newNavbaritems.push({value: "Documents (" + documentSectionTypeCount + ")", funktion: self.onDocumentSection});
           }
           if(videoSectionTypeCount > 0){
             newNavbaritems.push({value: "Videos (" + videoSectionTypeCount + ")", funktion: self.onVideoSection});
           }
         }

         self.setState({categoryLis: response.data.categories.filter(k => k.foundIn.length > 0),
                       productsLis: response.data.products.filter(k => k.foundIn.length > 0),
                       otherpageLis: response.data.otherpages.filter(k => k.foundIn.length > 0),
                       navbaritems: newNavbaritems,
                       isAllResults: true});
      });

    }else{
      this.setState({itemsList: [], navbaritems: []});
    }
  }

  onAllResults = (index) => {
    this.setState({activeItem: index});
    let arrayCategory = this.state.categoryLis.map((k) => {return ({link: "/categorypage?categoryId=" + k.id , title: k.title, description: k.description, url: k.image.url})});
    let arrayProducts = this.state.productsLis.map(k => {return ({link: "/productpage?categoryId=" + k.categoryId +"&productId=" + k.id , title: k.title, description: k.description, url: k.firstImage!==null ? k.firstImage.url : ""})});
    let arrayOtherpages = this.state.otherpageLis.map(k => {return ({link: "/page/" + k.shortlink , title: k.name, description: k.description, url: k.firstImage!==null ? k.firstImage.url : ""})});
    let array= arrayCategory.concat(arrayProducts, arrayOtherpages);
    this.setState({itemsList: array, isAllResults: false});
    this.listInnerRef.current.scrollIntoView({});
  }
  onCategory = (index) => {
    let arrayCategory = this.state.categoryLis.map((k) => {return ({link: "/categorypage?categoryId=" + k.id , title: k.title, description: k.description, url: k.image.url})});
    this.setState({activeItem: index, itemsList: arrayCategory});
    this.listInnerRef.current.scrollIntoView({});
  }
  onOtherPage = (index) => {
    let arrayOtherpages = this.state.otherpageLis.map(k => {return ({link: "/page/" + k.shortlink , title: k.name, description: k.description, url: k.firstImage!==null ? k.firstImage.url : ""})});
    this.setState({activeItem: index, itemsList: arrayOtherpages});
    this.listInnerRef.current.scrollIntoView({});
  }
  onProducts = (index) => {
    let arrayProducts = this.state.productsLis.map(k => {return ({link: "/productpage?categoryId=" + k.categoryId +"&productId=" + k.id, title: k.title, description: k.description, url: k.firstImage!==null ? k.firstImage.url : ""})});
    this.setState({activeItem: index, itemsList: arrayProducts});
    this.listInnerRef.current.scrollIntoView({});
  }

  onFoundInWithoutObject = (text) => {
    let arrayProductsWithoutFilter = [];
    let arrayProducts = [];
    arrayProductsWithoutFilter = this.state.productsLis.filter(k => k.foundIn.length > 0)
    for(let i=0; i<arrayProductsWithoutFilter.length; i++){
      let arrayWithoutMap = arrayProductsWithoutFilter[i].foundIn;
      let mapping = arrayWithoutMap.filter(k => k === text);
      if(mapping.length>0){
          arrayProducts.push(arrayProductsWithoutFilter[i]);
      }
    }
    return arrayProducts.map(k => {return ({link: "/productpage?categoryId=" + k.categoryId +"&productId=" + k.id , title: k.title, description: k.description, url: k.firstImage!==null ? k.firstImage.url : ""})});
  }
  onName = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithoutObject("name")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onTitle = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithoutObject("title")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onDescriptionTitle = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithoutObject("descriptionTitle")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onDescription = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithoutObject("description")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onFoundInWithObject = (text) => {
    let arrayProductsWithoutFilter = [];
    let arrayProducts = [];
    let array = [];
    arrayProductsWithoutFilter = this.state.productsLis.filter(k => k.foundIn.length > 0)
    for(let i=0; i<arrayProductsWithoutFilter.length; i++){
      let arrayWithoutMap = arrayProductsWithoutFilter[i].foundIn;
      let mapping = arrayWithoutMap.map((k) => ({sectionType: k.sectionType})).filter(k => k.sectionType=== text);
      if(mapping.length>0){
          arrayProducts.push(arrayProductsWithoutFilter[i]);
      }
    }
    return arrayProducts.map(k => {return ({link: "/productpage?categoryId=" + k.categoryId +"&productId=" + k.id , title: k.title, description: k.description, url: k.firstImage!==null ? k.firstImage.url : ""})});
  }
  onTextSection = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithObject("TEXT")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onLinkSection = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithObject("LINK")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onDocumentSection = (index) => {
    this.setState({activeItem: index, itemsList: this.onFoundInWithObject("DOCUMENT")});
    this.listInnerRef.current.scrollIntoView({});
  }
  onVideoSection = (index) => {
    lthis.setState({activeItem: index, itemsList: this.onFoundInWithObject("VIDEO")});
    this.listInnerRef.current.scrollIntoView({});
  }

  truncateString = (str, num) => {
    if(str !== null){
      if (str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
    }
  }

  render(){
    let paddingHeight = 52;
    paddingHeight = this.props.context.mainOffsetHeight !== undefined ? this.props.context.mainOffsetHeight +
                          this.props.context.submenuOffsetHeight +
                          this.props.context.linkOffsetHeight + 49 : 0;
    let refMenu = this.state.navbaritems.map((k, n) => {
      return( <MDBNavbarItem key={n} active={this.state.activeItem === n}>
                <MDBNavbarLink to="#" onClick={() => k.funktion(n)} active={this.state.activeItem === n} className="d-flex align-items-center justify-content-center">
                  {k.value}
                </MDBNavbarLink>
              </MDBNavbarItem>
      )
    })
    let itemsList = this.state.itemsList.map((k,n) => {
        return (
          <Link to={k.link}  key={n}>
          <div className="pb-3">
          <MDBRow className="m-0 pb-3" style={{borderBottom: "1px solid #D8D8D9"}}>
            <MDBCol md="2" className="p-0">
              {k.url === "" ?
              <div className="quetion-card-products-search d-flex align-items-center justify-content-center">
                  <img src={question}/><br/>
              </div>:
              <img
                src={k.url}
                className=' img-thumbnail img-fluid hover-shadow'
                alt=''
              />}
            </MDBCol>
            <MDBCol md="10" className="p-0">
              <MDBRow className="m-0 pb-2">
                <span className="title-text">{k.title}</span>
              </MDBRow>
              <MDBRow className="m-0">
                <span className="detail-text-search" dangerouslySetInnerHTML={{__html: this.truncateString(k.description, 130)}} />
              </MDBRow>
            </MDBCol>
          </MDBRow>
          </div>
          </Link>
        )
      })

    return (
      <>
      {this.state.categoryLis.length > 0 || this.state.productsLis.length > 0 || this.state.otherpageLis.length > 0 ?
      <div className="productpageNavbar" id="productpageNavbar-id">
      <MDBNavbar light bgColor='light'
                 fixed='top'
                 expand='lg'
                 id="withoutScroll"
                 style={{top: this.props.context.mainOffsetHeight !== undefined ? this.props.context.mainOffsetHeight + this.props.context.submenuOffsetHeight + this.props.context.linkOffsetHeight : 0}}>
        <MDBContainer >
          <MDBNavbarNav id="navbarrow" className='mr-auto mb-2 mb-lg-0'>
            {refMenu}
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
      </div>:false}
      <MDBContainer>
      <div ref={this.listInnerRef} style={{scrollMarginTop: paddingHeight}}/>
      <div className="py-3">
          {itemsList}
      </div>
      </MDBContainer>
      </>

    );
  }
}
export default function SearchPagePageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <SearchPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}/>;
}

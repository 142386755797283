import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Lightbox from '../Lightbox/Lightbox.js'

import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBLightbox,
  MDBLightboxItem,
} from 'mdb-react-ui-kit';

class ImagesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {activeItem: 0, isOpen: true};
  }

  toggle = tab => e => {

				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
	}

  onIsOpen = () => {
	  //this.setState({isOpen: !this.state.isOpen});
    this.props.outsideClickRef.current?.click();
	}



	render() {
    let boxitem = this.props.array.map((k,n) => {
      return(
        <MDBLightboxItem
          key={n}
          fullscreenSrc={k.url}

          ref={this.props.outsideClickRef}
        />
      )
    })
    return  (
        <>
						<div className="star">
            <MDBRow>
            {this.props.array.length > 1 ?
            <MDBCol md="auto" size="auto">
						<MDBNavbar className="flex-row">
							{this.props.array.map(	(o, index) =>
                <MDBNavbarNav key={index}>
										<MDBNavbarItem className="pb-3">
											<MDBNavbarLink to="#" active={this.state.activeItem === index} onClick={this.toggle(index)} role="tab" >
												<img alt="" className={this.state.activeItem === index ? "product-img-small-border" : "product-img-small"} src={o.url}/>
											</MDBNavbarLink>
										</MDBNavbarItem>
                </MDBNavbarNav>
							)}
						</MDBNavbar>
            </MDBCol>:false}
            <MDBCol md="auto" size="7">
            <a href="#!"  onClick={this.onIsOpen}>
            <img alt="" className="product-img-big" src={this.props.array[this.state.activeItem].url}/>
            </a>
            {this.state.isOpen ?
              <MDBLightbox style={{height:0}}>
                {boxitem}
              </MDBLightbox>:false}
            </MDBCol>
            </MDBRow>
            </div>
        </>
  );
  }
}
export default function ImagesComponentFunc(props) {
    const outsideClickRef = useRef(null);
    return <ImagesComponent  array={props.array} outsideClickRef={outsideClickRef}/>;
}

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import trash from "../../../assets/media/icons/Trash-red.svg";

import {
  MDBRow,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';

class UrlSection extends Component {

  constructor(props) {
    super(props);
    this.state = {url: this.props.url, title: this.props.name};
  }



  onTitleChange = (event) => {
    this.setState({title: event.target.value});
    this.props.addNameToSection(this.props.index, event.target.value)
	}

  onUrlChange = (event) => {
    this.setState({url: event.target.value});
    this.props.addUrlToSection(this.props.index, event.target.value)
	}

	render() {
    return !this.props.trashBin ? (
        <MDBRow className='py-3'>
            <span className="add-title-text">Link section
              <MDBBtn type="btn" className="deletefunction-btn" onClick={() => this.props.onDeleteSection(this.props.index)}><img src={trash}/></MDBBtn>
            </span>
            <span className="section-submneu-text">Link section name</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='title'
                        value={this.state.title}
                        onChange={this.onTitleChange}/>
          </div>
            <span className="section-submneu-text">Link URL</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='url'
                        value={this.state.url}
                        onChange={this.onUrlChange}/>
          </div>
        </MDBRow>
  ) : <></>;
  }
}
export default UrlSection;

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import ModalTemplate from "../ModalTemplate";

import documentsection from "../../../../assets/media/icons/Document section.svg";
import videosection from "../../../../assets/media/icons/Video section.svg";
import textsection from "../../../../assets/media/icons/Text section.svg";
import linksection from "../../../../assets/media/icons/Link section.svg";

import {
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';

class SelectSectionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      btnForm: [  {classname: "save" , onclick: this.props.hideModal , text: "Close"}
               ]
    };
  }

  internalHandleSave = () => {
    //console.log("Felülírtam");
    this.props.hideModal();
  }

	render() {
    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={this.state.btnForm}
          modalId={"selectsection"}
  			>
        <MDBRow className="m-0">
          <MDBCol md="4" className="px-0 d-flex justify-content-center">
            <MDBBtn type="btn" onClick={this.props.addTextSection} className="select-section-btn">
              <img src={textsection}/><br/>
              <span>Add Text section</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol md="4" className="px-0 d-flex justify-content-center">
            <MDBBtn type="btn" onClick={this.props.addDocumentSection} className="select-section-btn">
              <img src={documentsection}/><br/>
              <span>Add new Document</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol md="4" className="px-0 d-flex justify-content-center">
            <MDBBtn type="btn" onClick={this.props.addVideoSection} className="select-section-btn">
              <img src={videosection}/><br/>
              <span>Add new Video</span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow className="m-0 pt-4">
          <MDBCol md={!this.props.showSoftwareSection ? "6" : "12"} className="pe-4 d-flex justify-content-end">
            <MDBBtn type="btn" onClick={this.props.addUrlSection} className="select-section-btn">
              <img src={linksection}/><br/>
              <span>Add new Link</span>
            </MDBBtn>
          </MDBCol>
          {!this.props.showSoftwareSection ?
          <MDBCol md="6" className="ps-4 d-flex justify-content-start">
            <MDBBtn type="btn" onClick={this.props.addSoftwareSection} className="select-section-btn">
              <img src={linksection}/><br/>
              <span>Add new Software</span>
            </MDBBtn>
          </MDBCol>: false}
        </MDBRow>

      </ModalTemplate>
  );
  }
}
export default SelectSectionModal;

import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdb-react-ui-kit';
import Imagecrop from './Imagecrop/Imagecrop'
import ModalTemplate from "../ModalTemplate";

class CreateImageCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
                  };
  };

  componentDidMount() {
  };

  componentWillUnmount() {

  }

internalHandleSave = () => {
  this.props.onCropComplete(this.state.croppedImage, this.props.categoryId !== undefined ? this.props.categoryId : "");
  this.props.hideModal();
}

onMakeCroppedImage = (croppedImage) => {
  this.setState({croppedImage : croppedImage})

}



  render() {
    let btnForm = [  {classname: "cancel" , onclick: this.props.hideModal , text: "Cancel"},
                {classname: "save" , onclick:  this.state.croppedImage !== undefined ? this.internalHandleSave : this.props.hideModal , text: "Save"}
             ]
    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={btnForm}
          modalId={""}
  			>
         <Imagecrop
            onCropComplete={this.props.onCropComplete} categoryId={this.props.categoryId} onMakeCroppedImage={this.onMakeCroppedImage} hideModal={this.props.hideModal}

            />
      </ModalTemplate>
    );
  }
}

export default CreateImageCategory;

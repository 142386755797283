import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation } from 'react-router-dom';
import AppContext from '../../../AppContext';
import dropdownmenu from "../../../assets/media/icons/dropDown Menu.svg";
import question from "../../../assets/media/logo/Danfoss-logo.png";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem
} from 'mdb-react-ui-kit';


class CardTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
									};
  };

  render(){
    let dropdownItemLis = [];
    let self = this;
    dropdownItemLis = this.props.dropDownLis.map(
      (k, n) => {
            return (
              <MDBDropdownItem key={k.id} link className={k.id === "1" ? "pt-3" : "pt-2"} onClick={() => k.function(self.props.id, self.props.title, self.props.url, self.props.description)}><img src={k.url}/><span>{k.text}</span></MDBDropdownItem>
            );
          }
    )

    return (
      <MDBCard className="produsts-card">
        {this.props.url == null ?
        <div className="quetion-card d-flex align-items-center justify-content-center">
            <img src={question}/><br/>
        </div>:
        <MDBCardImage src={this.props.url} position='top' alt='...' />}
        <MDBCardBody>
          <div className="dropdown-icon d-flex align-items-center justify-content-center">
            <MDBDropdown>
              <MDBDropdownToggle><img src={dropdownmenu}/></MDBDropdownToggle>
              <MDBDropdownMenu>
                <div className="popover-arrow"/>
                {dropdownItemLis}
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>
          <MDBCardTitle>{this.props.title}</MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
export default function CardTemplateFunc(props) {
    const context = useContext(AppContext);
    return <CardTemplate context={context} url={props.url} dropDownLis={props.dropDownLis} title={props.title} id={props.id} description={props.description}/>;
}

import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import trash from "../../../assets/media/icons/Trash-red.svg";

import {
  MDBRow,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';

class TextSection extends Component {

  constructor(props) {
    super(props);
    this.state = {text: this.props.text, title: this.props.name};
  }

  onTitleChange = (event) => {
    this.setState({title: event.target.value});
    this.props.addNameToSection(this.props.index, event.target.value)
	}

  onArticleChange = (editorState) => {
    this.setState({text: editorState});
  	this.props.addTextToSection(this.props.index, editorState)
  }

	render() {
    return !this.props.trashBin ? (
        <MDBRow className='py-3'>
          <span className="add-title-text">Text section
            <MDBBtn type="btn" className="deletefunction-btn" onClick={() => this.props.onDeleteSection(this.props.index)}><img src={trash}/></MDBBtn>
          </span>
          <span className="section-submneu-text">Text section name</span>
          <div style={{maxWidth: "553px"}}>
            <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='title'
                        value={this.state.title}
                        onChange={this.onTitleChange}/>
          </div>
          <span className="section-submneu-text">Flowing Text</span>
          <div style={{maxWidth: "553px"}}>
            <CKEditor
              editor={ ClassicEditor }
              data={this.state.text}
              config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
              onChange={
                ( event, editor ) => {
                  const data = editor.getData();
                  this.onArticleChange(data);
                }
              }
            />
          </div>
        </MDBRow>
  ) : <></>;
  }
}
export default TextSection;
